import React from "react";
import {designData} from "../../pages/data/widgetData";
import StyleEditor from "./StyleEditor";

function Design() {
  return (
    <section>
      <p className="text-sm max-w-[460px] pb-2xl">
        {designData.infoText}
      </p>
      <StyleEditor />
    </section>
  )
}

export default Design;