import React from "react";
import { NavLink } from "react-router-dom";
import SettingsIcon from "../../Icons/Settings";
import ProfileIcon from "../../Icons/Profile";
import LogoutIcon from "../../Icons/Logout";
import classes from "../sidebarClasses";

function MetaNav({ sidebarState }) {
  const navItemMetaClasses = `${classes.navItem} ${classes.navItemMeta} ${classes.navItemHover}`;

  let labelClasses = sidebarState ? classes.navItemLabel : "hidden";

  return (
    <nav>
      <ul>
        <li>
          <NavLink
            to="/settings"
            className={({ isActive }) =>
              isActive
                ? `${classes.navItemActive} ${navItemMetaClasses}`
                : navItemMetaClasses
            }
          >
            <div className={classes.navItemIcon}>
              <SettingsIcon />
            </div>
            <span className={labelClasses}>Settings</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/profile"
            className={({ isActive }) =>
              isActive
                ? `${classes.navItemActive} ${navItemMetaClasses}`
                : navItemMetaClasses
            }
          >
            <div className={classes.navItemIcon}>
              <ProfileIcon />
            </div>
            <span className={labelClasses}>Profile</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/logout"
            className={({ isActive }) =>
              isActive
                ? `${classes.navItemActive} ${navItemMetaClasses}`
                : navItemMetaClasses
            }
          >
            <div className={classes.navItemIcon}>
              <LogoutIcon />
            </div>
            <span className={labelClasses}>Logout</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default MetaNav;
