import React from "react";

function Logout({
  width = 20,
  height = 20,
  fill = "currentcolor",
  className
}) {
  return (
    <svg 
      className={className} 
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`} 
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8011 10.5092C18.0663 10.2219 18.0663 9.77907 17.8011 9.49178L14.8011 6.24178C14.5201 5.93741 14.0457 5.91843 13.7413 6.19939C13.4369 6.48034 13.4179 6.95483 13.6989 7.2592L15.537 9.25049L8.75 9.25049C8.33579 9.25049 8 9.58628 8 10.0005C8 10.4147 8.33579 10.7505 8.75 10.7505L15.537 10.7505L13.6989 12.7418C13.4179 13.0461 13.4369 13.5206 13.7413 13.8016C14.0457 14.0825 14.5201 14.0636 14.8011 13.7592L17.8011 10.5092ZM10 18.2505C10 17.8363 9.66421 17.5005 9.25 17.5005L4.75 17.5005C4.05964 17.5005 3.5 16.9408 3.5 16.2505L3.5 3.75049C3.5 3.06013 4.05964 2.50049 4.75 2.50049L9.25 2.50049C9.66421 2.50049 10 2.1647 10 1.75049C10 1.33628 9.66421 1.00049 9.25 1.00049L4.75 1.00049C3.23122 1.00049 2 2.2317 2 3.75049L2 16.2505C2 17.7693 3.23122 19.0005 4.75 19.0005L9.25 19.0005C9.66421 19.0005 10 18.6647 10 18.2505Z"
      />
    </svg>
  );
}

export default Logout;