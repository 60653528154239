import React from "react";

function Plus({
  width = 20,
  height = 20,
  fill = "currentcolor",
  className
}) {
  return (
    <svg 
      className={className} 
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`} 
      fill={fill}
    >
       <path d="M10.834 4.16634C10.834 3.7061 10.4609 3.33301 10.0007 3.33301C9.54041 3.33301 9.16732 3.7061 9.16732 4.16634V9.16634H4.16732C3.70708 9.16634 3.33398 9.53944 3.33398 9.99967C3.33398 10.4599 3.70708 10.833 4.16732 10.833H9.16732V15.833C9.16732 16.2932 9.54041 16.6663 10.0007 16.6663C10.4609 16.6663 10.834 16.2932 10.834 15.833V10.833H15.834C16.2942 10.833 16.6673 10.4599 16.6673 9.99967C16.6673 9.53944 16.2942 9.16634 15.834 9.16634H10.834V4.16634Z" />
    </svg>
  );
}

export default Plus;