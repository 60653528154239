import { gql } from "@apollo/client";

export const profileQuery = gql`
  query Profile {
    user {
      id
      email
      name
    }
    organization {
      id
      subscription {
        id
        attributes {
          card_brand
          card_last_four
          created_at
          product_id
          product_name
          renews_at
          status
          updated_at
          user_email
          user_name
        }
      }
      websites {
        id
        domain
        title
        settings {
          autoVoiceGeneration
          autoAnswerGeneration
        }
        plan {
          creditPrice
          isAnswerGenerationAllowed
          label
          isAudioGenerationAllowed
          name
          lemonName
          productId
          price
          shareId
          isPaid
          storage {
            contacts
            knowledge
            queries
            questions
            users
            websites
          }
        }
      }
    }
  }
`;

export default profileQuery;
