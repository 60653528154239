import React from "react";
import { useRoutes } from "react-router-dom";

import Onboarding from "./pages/onboarding/onboard";

import Dashboard from "./pages/dashboard";
import Questions from "./pages/questions";
import Notes from "./pages/notes";

import Contacts from "./pages/contacts";
import Activities from "./pages/activities";

import Settings from "./pages/settings";
import Profile from "./pages/Profile";

import CacheQueries from "./pages/queries";
import NoAnswerQueries from "./pages/noanswerqueries";

import WidgetInstallation from "./components/WidgetSettings/Installation";
import WidgetContent from "./components/WidgetSettings/Content";
import WidgetDesign from "./components/WidgetSettings/Design";
import WidgetDemo from "./components/WidgetSettings/Demo";

const DashboardRoutes = () => {
  return useRoutes([
    { path: "/overview/*", element: <Dashboard /> },
    { path: "/settings/*", element: <Settings /> },
    {
      path: "/knowledge/*",
      children: [
        { path: "notes/*", element: <Notes /> },
        { path: "questions/*", element: <Questions /> }
      ]
    },
    { path: "/contacts/*", element: <Contacts /> },
    { path: "/activities/*", element: <Activities /> },
    {
      path: "/queries/*",
      children: [
        { path: "cache/*", element: <CacheQueries /> },
        { path: "no-answer/*", element: <NoAnswerQueries /> }
      ]
    },
    { path: "/onboarding", element: <Onboarding /> },
    {
      path: "/widget/*",
      children: [
        { path: "installation/*", element: <WidgetInstallation /> },
        { path: "content/*", element: <WidgetContent /> },
        { path: "design/*", element: <WidgetDesign /> },
        { path: "demo/*", element: <WidgetDemo /> }
      ]
    },
    { path: "/profile/*", element: <Profile /> }
  ]);
};

export default DashboardRoutes;
