import { gql } from "@apollo/client";

export const UpdateBillingMutation = gql`
  mutation UpdateBilling($orderId: orderId_String_NotNull_maxLength_200!) {
    updateBilling(orderId: $orderId)
  }
`;

export const GetPlansQuery = gql`
  query Plans {
    plans {
      creditPrice
      isAnswerGenerationAllowed
      isAudioGenerationAllowed
      label
      lemonName
      name
      price
      productId
      shareId
      isPaid
      storage {
        contacts
        knowledge
        queries
        questions
        users
        websites
      }
    }
  }
`;

export const SetFreePlanMutation = gql`
  mutation SetFreePlan {
    setFreePlan
  }
`;
