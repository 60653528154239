import React from "react";

function NotesTableHead({ tableHeadTitles }) {
  return (
    <thead>
      <tr>
        {tableHeadTitles.map((head, index) => (
          <th
            key={head}
            className={`bg-neutral-light/60 p-4 transition-colors ${
              index === 0 ? "pl-4xl w-2/3" : ""
            }`}
          >
            <h5 className="flex items-center gap-2 font-normal text-neutral-dark-l02 text-sm py-2 leading-none">
              {/* {index ===  0 && (
                <input 
                  type="checkbox" 
                  className='flex items-center justify-center w-xl h-xl appearance-none border border-neutral-light-d02 bg-transparent rounded before:scale-0 before:block before:rounded-sm before:w-xs before:h-xs before:bg-accent-secondary checked:before:scale-100' 
                />
              )} */}
              {head}
            </h5>
          </th>
        ))}
      </tr>
    </thead>
  );
}
export default NotesTableHead;
