import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAddQuestion } from "./questions.hook";

import Input from "../BasicElements/Input/Input";
import Button from "../BasicElements/Button/Button";
import Toggle from "../BasicElements/Toggle/Toggle";

// import ImagePlusIcon from "../Icons/ImagePlus";

import AnswerInput from "./AnswerInput";
import References from "./References";

const AddQuestion = () => {
  const navigate = useNavigate();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);
  const [references, setReferences] = useState([]);

  const [isAdding, setIsAdding] = useState(false);

  const { addQuestion } = useAddQuestion();

  // Uploading file functionality

  // const fileTypes = ["image/jpeg", "image/png", "image/webp"];

  // const validFileType = file => {
  //   return fileTypes.includes(file.type);
  // };

  // const returnFileSize = number => {
  //   if (number < 1024) {
  //     return `${number} bytes`;
  //   } else if (number >= 1024 && number < 1048576) {
  //     return `${(number / 1024).toFixed(1)} KB`;
  //   } else if (number >= 1048576) {
  //     return `${(number / 1048576).toFixed(1)} MB`;
  //   }
  // };

  // const [fileInfo, setFileInfo] = useState(null);
  // const handleFileUpload = e => {
  //   const file = e.target.files[0];
  //   if (validFileType(file)) {
  //     setFileInfo({
  //       name: file.name,
  //       size: returnFileSize(file.size)
  //     });
  //   }
  // };

  // Creating a question

  const addQuestionHandler = async () => {
    setIsAdding(true);
    await addQuestion({
      question,
      answer,
      isFeatured,
      references
    });
    navigate("/knowledge/questions");
  };

  return (
    <section className="w-full bg-neutral-light-l01 rounded p-4xl mb-2xl">
      <h3 className="font-medium text-2xl max-w-2xl">New question</h3>
      <form className="pt-xl md:pb-36 max-w-4xl">
        {/* Title */}
        <Input
          label="Question"
          type="text"
          placeholder="e.g. How can I change settings?"
          disabled={isAdding}
          value={question}
          onChange={e => setQuestion(e.target.value)}
        />
        {/* Highlight */}
        <Toggle
          label="Add this question to highlights"
          disabled={isAdding}
          checked={isFeatured}
          onChange={e => setIsFeatured(e.target.checked)}
          className="mb-3xs pt-0"
        />
        {/* Answer */}
        <AnswerInput
          question={question}
          initialAnswer={""}
          onChange={answer => setAnswer(answer)}
          disabled={isAdding}
        />
        {/* URL */}
        <References
          initialReferences={null}
          onChange={refs => setReferences(refs)}
        />

        {/* File */}
        {/* <div className="flex items-center">
          <div>
            <span className="block text-sm text-neutral-dark-l01 mb-esm">Related image</span>
            <Input type="file" label="Upload image" value={fileInfo} onChange={handleFileUpload} fileTypes={fileTypes}>
              <ImagePlusIcon />
            </Input>
          </div>
          <span className="block pt-emd text-xs text-neutral-dark-l02 max-w-[180px]">Recommended size is less than 1MB in .png or .jpg formats.</span>
        </div> */}
        {/* Action buttons */}
        <div className="flex pt-2">
          <Button 
            onClick={addQuestionHandler}
            disabled={isAdding}
            className="mr-3xl"
          >
            {isAdding ? "Creating..." : "Create"}
          </Button>
          <Button
            type="tertiary"
            size="sm"
            onClick={() => {
              navigate("/knowledge/questions");
            }}
          >
            Back to all
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddQuestion;
