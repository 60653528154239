import React, { useState } from "react";
import Toggle from "../BasicElements/Toggle/Toggle";
import SmallLoader from "../Loader/SmallLoader";

import { useGetUser } from "../../hooks/index";
import { useUpdateAutoVoiceGeneration } from "./settings.hook";

const Audio = () => {
  // TODO: replace useGetUser with useGetWebsite and select the current website
  const { loading, currentWebsite } = useGetUser();

  const { updateAutoVoiceGeneration } = useUpdateAutoVoiceGeneration();

  const [isUpdating, setIsUpdating] = useState(false);

  const isChecked = currentWebsite?.settings?.autoVoiceGeneration;

  const onChange = async e => {
    setIsUpdating(true);
    await updateAutoVoiceGeneration(e.target.checked);
    setIsUpdating(false);
  };

  return (
    <div className="py-lg max-w-xl">
      {isUpdating || loading ? (
        <SmallLoader
          className="py-xs"
          style={{
            height: "54px"
          }}
        />
      ) : (
        <Toggle
          label="Enable audio generation for answers"
          checked={isChecked}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default Audio;
