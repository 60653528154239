import React from "react";
import SearchGlass from '../Icons/SearchGlass';

function TableSearch({searchPlaceholder, onChange, value}) {
  return (
    <header className="px-4xl pt-2xl">
      <label className="pb-sm flex items-center text-sm text-neutral-dark placeholder:text-neutral-dark-l03">
        <div className="mr-exs text-neutral-dark-l03">
          <SearchGlass />
        </div>
        <input 
          className="grow border border-transparent py-esm focus:outline-none focus:border-b-accent-secondary-l02" 
          type="text" 
          value={value}  
          placeholder={searchPlaceholder} 
          onChange={onChange} 
        />
      </label>
    </header>
  )
}

export default TableSearch;