import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import Button from "../BasicElements/Button/Button";
import Loader from "../Loader/Loader";

import { installationData } from "../../pages/data/widgetData";
import { useGetCode } from "./widget.hook.js";

function Installation() {
  const [copied, setCopied] = useState(false);
  const { code, loading } = useGetCode();
  const areaRef = useRef(null);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2500);
    }
  }, [copied]);

  if (loading) return <Loader />;

  return (
    <div className="">
      <p className="text-sm max-w-[460px] mb-xl">{installationData.infoText}</p>
      <textarea
        className="rounded h-40 w-full block max-w-2xl text-sm p-6 border border-neutral-light-d02 resize-none bg-transparent mb-xl font-mono text-neutral-dark-l02 focus:outline-none focus:ring focus:ring-accent-secondary-l03"
        ref={areaRef}
        readOnly
        value={code}
      ></textarea>
      <div className="sm:flex items-center justify-between max-w-2xl">
        <Button
          onClick={() => {
            navigator.clipboard.writeText(areaRef.current.value);
            setCopied(true);
          }}
        >
          {copied ? "Copied!" : "Copy code"}
        </Button>
        <Link
          to="mailto:support@kohorta.co?subject=Alfred: Need help with widget installation"
          className="inline-flex items-center bg-transparent text-neutral-dark-l02 font-medium py-exs rounded transition-all duration-500 whitespace-nowrap hover:text-accent-secondary-d01 active:text-neutral-dark focus:outline-none focus:ring focus:ring-accent-secondary-l03 text-sm"
        >
          Having troubles? Ask for help
        </Link>
      </div>
    </div>
  );
}

export default Installation;
