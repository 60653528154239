import { gql } from "@apollo/client";

export const AnswerSubscriptions = gql`
  query AnswerSubscriptions($webId: ID!) {
    answerSubscriptions(webId: $webId) {
      contactId
      createdAt {
        seconds
        nanoseconds
      }
      email
      id
      queryId
      query
      updatedAt {
        nanoseconds
        seconds
      }
    }
  }
`;

export const AnswerSubscription = gql`
  query AnswerSubscriptions($webId: ID!, $id: ID!) {
    answerSubscription(webId: $webId, id: $id) {
      contactId
      createdAt {
        nanoseconds
        seconds
      }
      email
      id
      query
      queryId
      updatedAt {
        seconds
        nanoseconds
      }
    }
  }
`;
