import { useQuery, useMutation } from "@apollo/client";
import { updateUserMutation } from "../../graphql/users";
import { profileQuery } from "../../graphql/profile";

export const useUpdateUserName = () => {
  const [updateUserName, { loading, error, data }] = useMutation(
    updateUserMutation,
    {
      refetchQueries: [{ query: profileQuery }]
    }
  );

  return {
    updateUserName: name => updateUserName({ variables: { name } }),
    loading,
    error,
    data
  };
};
