import React from "react";
import { Route, Routes } from "react-router-dom";

import Queries from "../components/Queries/Queries";
import QueriesItem from "../components/Queries/QueriesItem";

const QueriesPage = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Queries />} />
        <Route path="/:id" element={<QueriesItem />} />
      </Routes>
    </>
  );
};

export default QueriesPage;

