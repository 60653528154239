import { useQuery, useMutation } from "@apollo/client";
import { Activities, ActivityById } from "../../graphql/activities";
import { useGetCurrentWebsiteId } from "../../hooks/index";

export const useGetActivities = () => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data, refetch } = useQuery(Activities, {
    variables: { webId },
    skip: !webId,
    fetchPolicy: "network-only"
  });

  const { activities } = data || {};

  return { activities, refetch, loading };
};

export const useGetActivityById = id => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data, refetch } = useQuery(ActivityById, {
    variables: { webId, id },
    skip: !webId,
    fetchPolicy: "network-only"
  });

  const { activity } = data || {};

  return { activity, refetch, loading };
};
