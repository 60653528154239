import React from "react";
import { Route, Routes } from "react-router-dom";

import ContactItem from "../components/Contacts/ContactItem";
import ContactsList from "../components/Contacts/ContactsList";
import AnswerSubscriptions from "../components/Contacts/AnswerSubscriptions";
import AnswerRequest from "../components/Contacts/AnswerRequest";

const ContactsPage = () => {
  return (
    <>
      <Routes>
        <Route path="/subscribers" element={<AnswerSubscriptions />} />
        <Route path="subscribers/:id" element={<AnswerRequest />} />
        <Route path="/all/:id" element={<ContactItem />} />
        <Route path="/all" element={<ContactsList />} />
      </Routes>
    </>
  );
};

export default ContactsPage;
