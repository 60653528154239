import React, { useEffect, useRef } from "react";

import Loader from "../Loader/Loader";

import { useGetCode } from "./widget.hook.js";

function Demo() {
  const wrapper = useRef(null);
  const { src, loading } = useGetCode();

  useEffect(() => {
    if (!src) return;
    // add debug query param to src
    let url = src + "&debug=true";

    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    script.id = "alfred-script-loader";
    script.innerHTML = "...";
    wrapper.current.appendChild(script);

    return () => {
      script.remove();
    };
  }, [src, loading]);

  if (loading) return <Loader />;

  return <div ref={wrapper} className="p-2xl bg-neutral-light-l01 rounded"></div>;
}

export default Demo;
