import React, { useState, useEffect } from "react";
import Textarea from "../BasicElements/Input/Textarea";
import Button from "../BasicElements/Button/Button";
import BoltIcon from "../Icons/Bolt";
import { useGenerateAnswer } from "./questions.hook";

const AnswerInput = ({
  initialAnswer = "",
  question = "",
  onChange,
  disabled
}) => {
  const [answerLineCount, setAnswerLineCount] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [answer, setAnswer] = useState("");
  const { generateAnswer } = useGenerateAnswer();

  useEffect(() => {
    if (initialAnswer) {
      setAnswer(initialAnswer);
    }
  }, [initialAnswer]);

  const generateAnswerHandler = async e => {
    e.preventDefault();
    setIsGenerating(true);

    const { data } = await generateAnswer(question);
    const { isAnswered, answer } = data?.generateAnswer || {};
    if (isAnswered) {
      onAnswerChange(answer);
    } else {
      onAnswerChange("There is no enough data to generate an answer.");
    }

    setIsGenerating(false);
  };

  const onAnswerChange = (newAnswer = "") => {
    setAnswer(newAnswer);
    setAnswerLineCount(newAnswer?.split("\n").length);
    onChange(newAnswer);
  };

  const helpText = (
    <div className="flex items-center">
      <span>Recommended size is less than 800 characters per answer. Type your text or </span>
      <Button
          type="tertiary"
          size="sm"
          className="!py-0 ml-xs"
          disabled={isGenerating}
          onClick={generateAnswerHandler}
        >
        <div className="mr-4xs ml-[-0.25rem]">
          <BoltIcon />
        </div>
        {isGenerating ? "Generating..." : "Generate Answer"}
      </Button>
    </div>
  );

  return (
    <Textarea
      label="Answer"
      placeholder="Answer text…"
      helpText={helpText}
      maxLength={1200}
      className="min-h-[240px]"
      disabled={disabled}
      value={answer}
      onChange={e => onAnswerChange(e.target.value)}
    />
  );
};

export default AnswerInput;
