import React from "react";

function Queries({
  width = 20,
  height = 20,
  fill = "currentcolor",
  className
}) {
  return (
    <svg 
      className={className} 
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`} 
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.91957 1L15.0804 1.00001C15.6146 0.999995 16.0604 0.999987 16.4247 1.02975C16.8046 1.06078 17.1612 1.1279 17.4985 1.29974C18.0159 1.56339 18.4366 1.98409 18.7003 2.50153C18.8721 2.8388 18.9392 3.19545 18.9703 3.57534C19 3.93957 19 4.38541 19 4.91955V16.2499C19 17.7687 17.7688 18.9999 16.25 18.9999H3.75001C2.23122 18.9999 1.00001 17.7687 1.00001 16.2499L1 4.91957C0.999989 4.38541 0.99998 3.93956 1.02974 3.57533C1.06078 3.19545 1.12789 2.83879 1.29973 2.50153C1.56338 1.98408 1.98408 1.56338 2.50153 1.29973C2.83879 1.12789 3.19545 1.06078 3.57533 1.02974C3.93957 0.99998 4.38542 0.999989 4.91957 1ZM3.69748 2.52476C3.41035 2.54822 3.27307 2.5901 3.18251 2.63624C2.94731 2.75608 2.75608 2.94731 2.63624 3.18251C2.5901 3.27307 2.54822 3.41036 2.52476 3.69748C2.50058 3.99336 2.5 4.37757 2.5 4.95L2.50001 10.9999H4.51394C5.55556 10.9999 6.50778 11.5884 6.97361 12.5201L7.11804 12.8089C7.32978 13.2324 7.76261 13.4999 8.23607 13.4999H11.7639C12.2374 13.4999 12.6702 13.2324 12.882 12.8089L13.0264 12.5201C13.4922 11.5884 14.4444 10.9999 15.4861 10.9999H17.5V4.95001C17.5 4.37758 17.4994 3.99336 17.4752 3.69748C17.4518 3.41036 17.4099 3.27308 17.3638 3.18252C17.2439 2.94732 17.0527 2.75609 16.8175 2.63625C16.7269 2.59011 16.5897 2.54822 16.3025 2.52476C16.0066 2.50059 15.6224 2.50001 15.05 2.50001L4.95 2.5C4.37757 2.5 3.99336 2.50058 3.69748 2.52476ZM17.5 12.4999H15.4861C15.0126 12.4999 14.5798 12.7674 14.368 13.1909L14.2236 13.4797C13.7578 14.4114 12.8056 14.9999 11.7639 14.9999H8.23607C7.19445 14.9999 6.24223 14.4114 5.7764 13.4797L5.63197 13.1909C5.42023 12.7674 4.9874 12.4999 4.51394 12.4999H2.50001V16.2499C2.50001 16.9403 3.05965 17.4999 3.75001 17.4999H16.25C16.9404 17.4999 17.5 16.9403 17.5 16.2499V12.4999Z"
      />
    </svg>
  );
}

export default Queries;