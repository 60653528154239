import { useQuery, useMutation } from "@apollo/client";
import {
  UpdateBillingMutation,
  GetPlansQuery,
  SetFreePlanMutation
} from "../../graphql/billing";
import { profileQuery } from "../../graphql/profile";
import { useGetCurrentWebsiteId } from "../../hooks/index";

export const useUpdateBilling = () => {
  const webId = useGetCurrentWebsiteId();

  const [updateBilling, { loading, error, data }] = useMutation(
    UpdateBillingMutation,
    {
      variables: { webId },
      skip: !webId,
      refetchQueries: [{ query: profileQuery }]
    }
  );

  return {
    updateBilling: orderId => updateBilling({ variables: { orderId } }),
    loading,
    error,
    data
  };
};

export const useGetPlans = () => {
  const { loading, called, error, data } = useQuery(GetPlansQuery);

  return {
    loading: loading || !called,
    error,
    plans: data?.plans
  };
};

export const useSetFreePlan = () => {
  const [setFreePlan, { loading, error, data }] = useMutation(
    SetFreePlanMutation,
    {
      refetchQueries: [{ query: profileQuery }]
    }
  );

  return {
    setFreePlan,
    loading,
    error,
    data
  };
};
