import React from "react";

function DragDrop({width=20, height=20, fill='currentcolor', className}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill={fill}>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.41699 10C5.41699 9.07953 6.16318 8.33333 7.08366 8.33333C8.00413 8.33333 8.75033 9.07953 8.75033 10C8.75033 10.9205 8.00413 11.6667 7.08366 11.6667C6.16318 11.6667 5.41699 10.9205 5.41699 10Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.41699 4.16667C5.41699 3.24619 6.16318 2.5 7.08366 2.5C8.00413 2.5 8.75033 3.24619 8.75033 4.16667C8.75033 5.08714 8.00413 5.83333 7.08366 5.83333C6.16318 5.83333 5.41699 5.08714 5.41699 4.16667Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.41699 15.8333C5.41699 14.9129 6.16318 14.1667 7.08366 14.1667C8.00413 14.1667 8.75033 14.9129 8.75033 15.8333C8.75033 16.7538 8.00413 17.5 7.08366 17.5C6.16318 17.5 5.41699 16.7538 5.41699 15.8333Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2503 10C11.2503 9.07953 11.9965 8.33333 12.917 8.33333C13.8375 8.33333 14.5837 9.07953 14.5837 10C14.5837 10.9205 13.8375 11.6667 12.917 11.6667C11.9965 11.6667 11.2503 10.9205 11.2503 10Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2503 4.16667C11.2503 3.24619 11.9965 2.5 12.917 2.5C13.8375 2.5 14.5837 3.24619 14.5837 4.16667C14.5837 5.08714 13.8375 5.83333 12.917 5.83333C11.9965 5.83333 11.2503 5.08714 11.2503 4.16667Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2503 15.8333C11.2503 14.9129 11.9965 14.1667 12.917 14.1667C13.8375 14.1667 14.5837 14.9129 14.5837 15.8333C14.5837 16.7538 13.8375 17.5 12.917 17.5C11.9965 17.5 11.2503 16.7538 11.2503 15.8333Z" />
    </svg>
  )
}

export default DragDrop;