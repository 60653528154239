import { useQuery, useMutation } from "@apollo/client";
import {
  AnswerLessQueries,
  Queries,
  QueryById,
  DeleteQueryMutation
} from "../../graphql/queries";
import { useGetCurrentWebsiteId } from "../../hooks/index";

export const useGetQueries = () => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data, refetch } = useQuery(Queries, {
    variables: { webId },
    skip: !webId,
    fetchPolicy: "network-only"
  });

  const { queries } = data || {};

  return { queries, refetch, loading };
};

export const useGetQueryById = id => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data, refetch, called } = useQuery(QueryById, {
    variables: { webId, id },
    skip: !webId,
    fetchPolicy: "network-only"
  });

  const { query } = data || {};

  return { query, refetch, loading: loading || !called };
};

export const useGetAnswerLessQueries = () => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data, refetch } = useQuery(AnswerLessQueries, {
    variables: { webId },
    skip: !webId,
    fetchPolicy: "network-only"
  });

  const { answerLessQueries } = data || {};

  return { answerLessQueries, refetch, loading };
};

export const useDeleteQuery = () => {
  const webId = useGetCurrentWebsiteId();

  const [deleteQuery, { loading, error, data }] =
    useMutation(DeleteQueryMutation);

  return {
    deleteQuery: id => deleteQuery({ variables: { webId, id } }),
    loading,
    error,
    data
  };
};
