import React, { useState, useEffect } from "react";
import Input from "../BasicElements/Input/Input";
import Button from "../BasicElements/Button/Button";
import Toggle from "../BasicElements/Toggle/Toggle";
// We need TagIcon, ImagePlusIcon, LinkIcon if we add an option
// to upload files and add URLs to questions
// import ImagePlusIcon from "../Icons/ImagePlus";

import { useUpdateQuestion } from "./questions.hook";
import AnswerInput from "./AnswerInput";
import References from "./References";

const UpdateQuestion = ({ questionData = {}, onUpdateQuestion }) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);
  const [references, setReferences] = useState([]);

  const [isAdding, setIsAdding] = useState(false);

  const { updateQuestion } = useUpdateQuestion();

  useEffect(() => {
    setQuestion(questionData.question ?? "");
    setAnswer(questionData.answer ?? "");
    setIsFeatured(questionData.isFeatured ?? false);
    setReferences(questionData.references ?? []);
  }, [questionData]);

  const updateQuestionHandler = async () => {
    setIsAdding(true);
    await updateQuestion({
      id: questionData.id,
      question,
      answer,
      isFeatured,
      references
    });
    setIsAdding(false);
    onUpdateQuestion();
  };

  return (
    <>
      <h3 className="font-medium text-2xl max-w-2xl mb-e4xs">
        Question editor
      </h3>
      <form className="pt-lg max-w-4xl">
        {/* Title */}
        <Input
          label="Question"
          type="text"
          placeholder="e.g. How can I change settings?"
          disabled={isAdding}
          value={question}
          onChange={e => setQuestion(e.target.value)}
        />
        {/* Highlight */}
        <Toggle
          label="Add this question to highlights"
          disabled={isAdding}
          checked={isFeatured}
          onChange={e => setIsFeatured(e.target.checked)}
          className="mb-3xs pt-0"
        />
        {/* Automatic generation */}
        <AnswerInput
          question={question}
          initialAnswer={questionData.answer}
          onChange={answer => setAnswer(answer)}
          disabled={isAdding}
        />
        {/* Related links */}
        <References
          initialReferences={questionData?.references || null}
          onChange={refs => setReferences(refs)}
        />
        {/* Action buttons */}
        <footer className="md:flex mt-8">
          <Button
            onClick={updateQuestionHandler}
            disabled={isAdding}
            className="mr-3xl"
          >
            {isAdding ? "Saving..." : "Save"}
          </Button>
          <Button type="tertiary" size="sm" onClick={onUpdateQuestion}>
            Cancel
          </Button>
        </footer>
      </form>
    </>
  );
};

export default UpdateQuestion;
