import React, { useEffect, useState, useContext } from "react";

const isDev = process.env.NODE_ENV === "development";

import { useGetUser } from "../../hooks/index";
import { useScript } from "../../hooks/js-loader.hook";
import { useUpdateBilling, useGetPlans } from "./billing.hook";

const classes = {
  default:
    "inline-flex items-center bg-neutral-dark border border-neutral-dark text-neutral-light font-bold px-exl py-exs rounded transition-all duration-500 whitespace-nowrap text-base",
  hover: "hover:bg-transparent hover:text-neutral-dark",
  active: "active:bg-transparent",
  focus: "focus:outline-none focus:ring focus:ring-accent-secondary-l03"
};

const BillingBtn = ({ children }) => {
  const userProfile = useGetUser();
  const { updateBilling } = useUpdateBilling();
  const { loading, plans = [], error } = useGetPlans();

  const onSuccess = order => {
    updateBilling(order?.data?.id);
  };

  const email = userProfile?.user?.email;
  const userId = userProfile?.user?.id;
  const orgId = userProfile?.org?.id;
  const webId = userProfile?.currentWebsite?.id;
  const billingProductId = plans[1]?.shareId;
  const couponCode = "";

  useScript("https://app.lemonsqueezy.com/js/lemon.js", () => {
    window.createLemonSqueezy();
    window.LemonSqueezy.Setup({
      eventHandler: event => {
        if (event.event === "Checkout.Success") {
          const order = event?.data?.order;
          onSuccess(order);
          window.LemonSqueezy.Url.Close();
        }
      }
    });
  });

  return (
    <a
      className={`lemonsqueezy-button ${classes.default} ${classes.hover} ${classes.active} ${classes.focus}`}
      href={`https://alfredweb.lemonsqueezy.com/checkout/buy/${billingProductId}?embed=1&media=0&logo=0&desc=0&checkout[custom][orgid]=${orgId}&checkout[custom][userid]=${userId}&checkout[custom][webid]=${webId}&checkout[email]=${email}`}
    >
      {children || "Subscribe"}
    </a>
  );
};
export default BillingBtn;

/*
{
    "event": {
        "event": "Checkout.Success",
        "data": {
            "order": {
                "meta": {
                    "test_mode": true,
                    "custom_data": {
                        "orgid": "AKvZevwvIB5jP6qH7qM7",
                        "webid": "eDkETNtJfIyNJNWOogjJ",
                        "userid": "DBLsaOAjWjlGIuDvFOVr"
                    }
                },
                "data": {
                    "type": "orders",
                    "id": "1217172",
                    "attributes": {
                        "store_id": 12324,
                        "customer_id": 1217785,
                        "identifier": "d9806982-d260-425f-8284-e5ecaa6d8c4d",
                        "order_number": 7,
                        "user_name": "554",
                        "user_email": "r2@d.co",
                        "currency": "USD",
                        "currency_rate": "1.00000000",
                        "tax_name": null,
                        "tax_rate": "0.00",
                        "status": "paid",
                        "status_formatted": "Paid",
                        "refunded": false,
                        "refunded_at": null,
                        "subtotal": 1800,
                        "discount_total": 1620,
                        "tax": 0,
                        "total": 180,
                        "subtotal_usd": 1800,
                        "discount_total_usd": 1620,
                        "tax_usd": 0,
                        "total_usd": 180,
                        "subtotal_formatted": "$18.00",
                        "discount_total_formatted": "$16.20",
                        "tax_formatted": "$0.00",
                        "total_formatted": "$1.80",
                        "first_order_item": {
                            "id": 1182679,
                            "order_id": 1217172,
                            "product_id": 108641,
                            "variant_id": 122414,
                            "price_id": 97881,
                            "product_name": "Starter Alfred",
                            "variant_name": "Default",
                            "price": 1800,
                            "created_at": "2023-09-02T14:59:02.000000Z",
                            "updated_at": "2023-09-02T14:59:02.000000Z",
                            "test_mode": true
                        },
                        "urls": {
                            "receipt": "https://app.lemonsqueezy.com/my-orders/d9806982-d260-425f-8284-e5ecaa6d8c4d?signature=b2732d03dd8a0490ecbaa03c04032e8fd937d0da20a87245d40061474eb42cc9"
                        },
                        "created_at": "2023-09-02T14:58:55.000000Z",
                        "updated_at": "2023-09-02T14:59:02.000000Z",
                        "test_mode": true
                    },
                    "relationships": {
                        "store": {
                            "links": {
                                "related": "https://api.lemonsqueezy.com/v1/orders/1217172/store",
                                "self": "https://api.lemonsqueezy.com/v1/orders/1217172/relationships/store"
                            }
                        },
                        "customer": {
                            "links": {
                                "related": "https://api.lemonsqueezy.com/v1/orders/1217172/customer",
                                "self": "https://api.lemonsqueezy.com/v1/orders/1217172/relationships/customer"
                            }
                        },
                        "order-items": {
                            "links": {
                                "related": "https://api.lemonsqueezy.com/v1/orders/1217172/order-items",
                                "self": "https://api.lemonsqueezy.com/v1/orders/1217172/relationships/order-items"
                            }
                        },
                        "subscriptions": {
                            "links": {
                                "related": "https://api.lemonsqueezy.com/v1/orders/1217172/subscriptions",
                                "self": "https://api.lemonsqueezy.com/v1/orders/1217172/relationships/subscriptions"
                            }
                        },
                        "license-keys": {
                            "links": {
                                "related": "https://api.lemonsqueezy.com/v1/orders/1217172/license-keys",
                                "self": "https://api.lemonsqueezy.com/v1/orders/1217172/relationships/license-keys"
                            }
                        },
                        "discount-redemptions": {
                            "links": {
                                "related": "https://api.lemonsqueezy.com/v1/orders/1217172/discount-redemptions",
                                "self": "https://api.lemonsqueezy.com/v1/orders/1217172/relationships/discount-redemptions"
                            }
                        }
                    },
                    "links": {
                        "self": "https://api.lemonsqueezy.com/v1/orders/1217172"
                    }
                }
            }
        }
    }
}

*/
