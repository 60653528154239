import React, { useState, useEffect } from "react";
import Input from "../BasicElements/Input/Input";
import Textarea from "../BasicElements/Input/Textarea";
import Button from "../BasicElements/Button/Button";
import { useUpdateNote } from "./notes.hook";

const UpdateNote = ({
  noteData = {}, 
  onUpdateNote
}) => {
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const { updateNote } = useUpdateNote();

  useEffect(() => {
    setNote(noteData.body ?? "");
    setTitle(noteData.title ?? "");
  }, [noteData]);

  const updateNoteHandler = async () => {
    setIsAdding(true);
    await updateNote({
      id: noteData.id,
      body: note,
      title
    });
    setIsAdding(false);
    onUpdateNote();
  };

  return (
    <>
      <h3 className="font-medium text-2xl max-w-2xl mb-e4xs">
        Note editor
      </h3>
      <form className="pt-lg max-w-2xl">
        {/* Title */}
        <Input  
          label="Title" 
          type="text" 
          placeholder="e.g. About our process" 
          disabled={isAdding}
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
        {/* Text */}
        <Textarea
          label="Text"
          placeholder="Note text…" 
          helpText="Recommended size is less than 1250 characters per note." 
          maxLength={1250} 
          className="min-h-[240px]" 
          disabled={isAdding}
          value={note}
          onChange={e => setNote(e.target.value)}
        />
        {/* Action buttons */}
      <footer className="md:flex">
        <Button 
          onClick={updateNoteHandler}
          disabled={isAdding} 
          className="mr-3xl"
        >
          {isAdding ? "Saving..." : "Save"}
        </Button>
        <Button 
          type="tertiary" 
          size="sm" 
          onClick={onUpdateNote}
        >
          Cancel
        </Button>
      </footer>
      </form>
    </>
  )
};

export default UpdateNote;