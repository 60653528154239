import { useMemo } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  widgetCSSQuery,
  widgetCodeQuery,
  widgetFieldsQuery,
  saveWidgetCSSMutation,
  featuredQuestionsQuery,
  saveWidgetLabelsMutation,
  saveFeaturedQuestionsOrderMutation
} from "../../graphql/widget.js";

import { useGetCurrentWebsiteId } from "../../hooks/index";

export const useGetCode = () => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data } = useQuery(widgetCodeQuery, {
    variables: { webId },
    skip: !webId
  });

  const { code, src } = data?.installationCode || {};

  return { loading, error, code, src };
};

export const useGetCSS = () => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data } = useQuery(widgetCSSQuery, {
    variables: { webId },
    skip: !webId
  });

  const css = data?.widget?.css || "";

  return { loading, error, css };
};

export const useSaveCSS = () => {
  const webId = useGetCurrentWebsiteId();

  const [saveCSS, { loading, error, data }] = useMutation(
    saveWidgetCSSMutation,
    {
      variables: { webId },
      skip: !webId,
      refetchQueries: [{ query: widgetCSSQuery, variables: { webId } }]
    }
  );

  return {
    saveCSS: css => saveCSS({ variables: { css } }),
    loading,
    error,
    data
  };
};

export const useGetFeaturedQuestions = () => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data, called } = useQuery(featuredQuestionsQuery, {
    variables: { webId, isFeatured: true },
    fetchPolicy: "cache-and-network",
    skip: !webId
  });

  const questions = data?.questions;
  const featuredQuestionIds = data?.widget?.featuredQuestionIds;

  const featuredQuestions = useMemo(() => {
    const sorted = [];
    const unsorted = [];

    featuredQuestionIds?.forEach(id => {
      const q = questions?.find(q => q.id === id);
      sorted.push(q);
    });

    questions?.forEach(q => {
      if (!featuredQuestionIds?.includes(q.id)) {
        q.isFeatured && unsorted.push(q);
      }
    });

    return [...sorted, ...unsorted];
  }, [questions, featuredQuestionIds]);

  return { loading: loading || !called, error, questions, featuredQuestions };
};

export const useSaveFeaturedQuestionsOrder = () => {
  const webId = useGetCurrentWebsiteId();

  const [saveQuestionsOrder, { loading, error, data }] = useMutation(
    saveFeaturedQuestionsOrderMutation,
    {
      variables: { webId },
      skip: !webId
    }
  );

  return {
    saveQuestionsOrder: ids => saveQuestionsOrder({ variables: { ids } }),
    loading,
    error,
    data
  };
};

export const useGetWidgetFields = () => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data, called } = useQuery(widgetFieldsQuery, {
    variables: { webId },
    skip: !webId
  });

  const labels = data?.widget?.labels || null;
  const fields = data?.fields || null;

  return { loading: loading || !called, error, labels, fields };
};

export const useSaveWidgetLabels = () => {
  const webId = useGetCurrentWebsiteId();

  const [saveLabels, { loading, error, data }] = useMutation(
    saveWidgetLabelsMutation,
    {
      variables: { webId },
      skip: !webId,
      refetchQueries: [{ query: widgetFieldsQuery, variables: { webId } }]
    }
  );

  return {
    saveLabels: labels => saveLabels({ variables: { labels } }),
    loading,
    error,
    data
  };
};
