import React, { useState } from "react";
import Accordion from "../components/Accordion/Accordion";

import AudioGenerationSettings from "../components/WebsiteSettings/AudioGeneration";
import AnswerGenerationSettings from "../components/WebsiteSettings/AnswerGeneration";
import WebsiteDomain from "../components/WebsiteSettings/WebsiteDomain";

const Users = () => {
  return (
    <Accordion title="Users">
      <form className="py-lg max-w-xl"></form>
    </Accordion>
  );
};

const Websites = () => {
  return (
    <Accordion title="Website">
      <WebsiteDomain />
    </Accordion>
  );
};

const Audio = () => {
  return (
    <Accordion title="Audio">
      <AudioGenerationSettings />
    </Accordion>
  );
};

const AISettings = () => {
  return (
    <Accordion title="AI">
      <AnswerGenerationSettings />
    </Accordion>
  );
};

const SettingsPage = () => {
  return (
    <section>
      {/* <Users /> */}
      <Websites />
      <Audio />
      <AISettings />
    </section>
  );
};

export default SettingsPage;
