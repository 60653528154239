// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aeBg0DKSRIKiCahD8Qpg {\n  animation: biPdjg6JpeUpV8zUyqGQ 0.95s linear infinite;\n  border: 4px solid #D6D9CF;\n  border-bottom-color: #222426;\n  border-radius: 50%;\n  box-sizing: border-box;\n  display: inline-block;\n  height: 2rem;\n  width:  2rem;\n}\n\n@keyframes biPdjg6JpeUpV8zUyqGQ {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.module.css"],"names":[],"mappings":"AAAA;EACE,qDAAyC;EACzC,yBAAyB;EACzB,4BAA4B;EAC5B,kBAAkB;EAClB,sBAAsB;EACtB,qBAAqB;EACrB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loader {\n  animation: rotation 0.95s linear infinite;\n  border: 4px solid #D6D9CF;\n  border-bottom-color: #222426;\n  border-radius: 50%;\n  box-sizing: border-box;\n  display: inline-block;\n  height: 2rem;\n  width:  2rem;\n}\n\n@keyframes rotation {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "aeBg0DKSRIKiCahD8Qpg",
	"rotation": "biPdjg6JpeUpV8zUyqGQ"
};
export default ___CSS_LOADER_EXPORT___;
