import React from "react";
import moment from "moment";
import { Link, useParams, useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import Button from "../BasicElements/Button/Button";
import ArrowBack from "../Icons/ArrowDown";

import { useGetAnswerSubscription } from "./contacts.hook";

const ContactsItem = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { answerSubscription, loading } = useGetAnswerSubscription(id);

  // Below is a draft for a new hook to add to contacts.hook
  // so we can delete the query from the contact object
  // const { deleteQuery } = useDeleteQuery();
  // const handleDeleteQuery = async query => {
  //   await deleteQuery(query);
  //   navigate("/contacts/subscribers");
  // };

  if (loading) return <Loader />;

  if (!answerSubscription) return <div>Not found</div>;

  return (
    <section className="bg-neutral-light-l01 rounded p-4xl mb-2xl">
      {/* Back to all items */}
      <header>
        <Button 
          type="tertiary" 
          size="sm" 
          onClick={()=>{navigate("/contacts/subscribers")}}
        >
          <ArrowBack className="rotate-90 mr-3xs" />
          Back to all subscribers
        </Button>
      </header>
      <div className="py-3xl">
        <span className="text-neutral-dark-l03 text-sm block mb-xs">
          {new moment(answerSubscription.createdAt.seconds * 1000).format("MMM DD, YYYY")}
        </span>
        <h3 className="font-medium text-2xl max-w-2xl mb-e4xs">
          {answerSubscription.query}
        </h3>
        <p className="text-neutral-dark-l02 max-w-2xl">
          by {answerSubscription.email}
        </p>
      </div>
      {/* Action buttons */}
      <footer className="md:flex">
        {/* <Button 
          onClick={null}
          className="mr-3xl"
        >
          Contact
        </Button>  */}
        {/* <Button 
          type="tertiary" 
          size="sm"
          onClick={() => handleDeleteQuery(contact.query)}
        >
          Delete
        </Button> */}
      </footer>
    </section>
  );
};

export default ContactsItem;
