import React from "react";
import moment from "moment";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Loader from "../Loader/Loader";

import { useDeleteContact, useGetContacts } from "./contacts.hook";

const ContactsList = () => {
  const { pathname } = useLocation();
  const { contacts, loading } = useGetContacts();

  const { deleteContact } = useDeleteContact();

  const handleDelete = async id => {
    await deleteContact({ id });
  };

  if (loading) return <Loader />;

  return (
    <section>
      {/* Meta info */}
      <p className="text-sm text-neutral-dark-l02"><span className="font-bold text-neutral-dark">{contacts?.length}</span> contacts total for all time.</p>
      {/* Contact list */}
      <ul className="py-4xl">
        {contacts?.map(contact => (
          <li 
            key={contact.id} 
            className="mb-xs"
          >
            <Link 
              to={`${pathname}/${contact.id}`}
              className="block bg-neutral-light-l01 rounded p-xl transition-colors duration-300 hover:bg-neutral-light-l01/60"
            >
              <span className="text-neutral-dark-l03 text-sm block mb-3xs">
                Added: {new moment(contact.createdAt.seconds * 1000).format("MMM DD, YYYY")}
              </span>
              <h5 className="font-medium">{contact.email}</h5>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ContactsList;
