import React from "react";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../services/firebase";
import Loader from "../components/Loader/Loader";
import { useGetUser, useAuthUserByCustomToken } from "../hooks";

const Backdoor = () => {
  const [oldUserSignedOut, setOldUserSignedOut] = useState(false);
  const { user, loading: loadingCurrentUser } = useGetUser();

  //waiting for old user to sign out then signing in new user by token
  if (!oldUserSignedOut && !loadingCurrentUser) {
    if (user) {
      signOut(auth); //useGetUser will update user to null
    } else {
      setOldUserSignedOut(true);
    }
  }

  //useAuthUserByCustomToken will sign in new user by token if old user is signed out
  const { error: gError, loading: gUserLoading } =
    useAuthUserByCustomToken(oldUserSignedOut);

  if (gError) {
    return (
      <div>
        <div>{gError}</div>
        <a href="/login">Login</a>
        <br />
        <a href="/logout">Logout</a>
      </div>
    );
  }

  const loading = loadingCurrentUser || gUserLoading;

  if (loading || !oldUserSignedOut) {
    return <Loader />;
  }

  const render = <Navigate to="/overview" replace={true} />;

  return <>{render}</>;
};

export default Backdoor;
