import React from "react";

function Lock({
  width = 20,
  height = 20,
  fill = "currentcolor",
  className
}) {
  return (
    <svg 
      className={className} 
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`} 
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91602 6.99968C2.59385 6.99968 2.33268 7.26084 2.33268 7.58301V11.6663C2.33268 11.9885 2.59385 12.2497 2.91602 12.2497H11.0827C11.4048 12.2497 11.666 11.9885 11.666 11.6663V7.58301C11.666 7.26084 11.4048 6.99968 11.0827 6.99968H2.91602ZM1.16602 7.58301C1.16602 6.61651 1.94952 5.83301 2.91602 5.83301H11.0827C12.0492 5.83301 12.8327 6.61651 12.8327 7.58301V11.6663C12.8327 12.6328 12.0492 13.4163 11.0827 13.4163H2.91602C1.94952 13.4163 1.16602 12.6328 1.16602 11.6663V7.58301Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99935 1.74967C6.38051 1.74967 5.78702 1.99551 5.34943 2.43309C4.91185 2.87068 4.66602 3.46417 4.66602 4.08301V6.41634C4.66602 6.73851 4.40485 6.99967 4.08268 6.99967C3.76052 6.99967 3.49935 6.73851 3.49935 6.41634V4.08301C3.49935 3.15475 3.8681 2.26451 4.52448 1.60813C5.18085 0.951757 6.07109 0.583008 6.99935 0.583008C7.92761 0.583008 8.81785 0.951757 9.47422 1.60813C10.1306 2.26451 10.4993 3.15475 10.4993 4.08301V6.41634C10.4993 6.73851 10.2382 6.99967 9.91602 6.99967C9.59385 6.99967 9.33268 6.73851 9.33268 6.41634V4.08301C9.33268 3.46417 9.08685 2.87068 8.64927 2.43309C8.21168 1.99551 7.61819 1.74967 6.99935 1.74967Z"
      />
    </svg>
  );
}

export default Lock;