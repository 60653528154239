import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const useGetLoggedUser = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), user => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return unsubscribe;
  }, []);

  return user;
};

export default useGetLoggedUser;

export const useGetLoggedUserToken = () => {
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const user = useGetLoggedUser();

  useEffect(() => {
    if (user) {
      user
        .getIdToken(true)
        .then(function (idToken) {
          setToken(idToken);
          setIsLoading(false);
        })
        .catch(function (error) {
          console.log("error", error);
          setToken(null);
          setIsLoading(false);
        });
    }
  }, [user]);

  return [token, isLoading];
};
