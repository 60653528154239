import React from "react";
import Logo from "./Logo/Logo";
import WebsiteOptions from "./WebsiteOptions/WebsiteOptions";
import SidebarToggle from "./SidebarToggle/SidebarToggle";
import MainNav from "./MainNav/MainNav";
import MetaNav from "./MetaNav/MetaNav";
import classes from "./sidebarClasses";

function Sidebar({ handleSidebarToggle, toggleStateSidebar }) {
  const sidebarClasses = toggleStateSidebar
    ? `${classes.aside} ${classes.asideExpanded}`
    : `${classes.aside} ${classes.asideMinimized}`;

  return (
    <aside className={sidebarClasses}>
      {/* Top bar */}
      <header className="mb-2xl">
        <div className="mb-2xl pl-3xl pr-sm flex items-center justify-between">
          <Logo />
          <SidebarToggle
            togglePlacement="Sidebar"
            toggleState={toggleStateSidebar}
            handleSidebarToggle={handleSidebarToggle}
          />
        </div>
        <WebsiteOptions sidebarState={toggleStateSidebar} />
      </header>
      {/* Navigation */}
      <div className="flex flex-col justify-between grow mx-sm">
        <MainNav sidebarState={toggleStateSidebar} />
        <MetaNav sidebarState={toggleStateSidebar} />
      </div>
    </aside>
  );
}

export default Sidebar;
