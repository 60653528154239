import { gql } from "@apollo/client";

export const updateAutoVoiceGenerationMutation = gql`
  mutation AutoVoiceGeneration($webId: ID!, $isAuto: Boolean!) {
    saveAutoVoiceGeneration(webId: $webId, isAuto: $isAuto)
  }
`;

export const updateAutoAnswerGenerationMutation = gql`
  mutation AutoAnswerGeneration($webId: ID!, $isAuto: Boolean!) {
    saveAutoAnswerGeneration(webId: $webId, isAuto: $isAuto)
  }
`;

export const updateWebsiteDomainMutation = gql`
  mutation saveWebsiteDomain(
    $webId: ID!
    $domain: domain_String_NotNull_maxLength_200!
  ) {
    saveWebsiteDomain(webId: $webId, domain: $domain)
  }
`;
