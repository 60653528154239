import React from "react";

export const notesTabData = {
  tableHead: ["Notes", "Author", "Updated"],
  tableRows: [
    {
      noteTitle: "About Alfred",
      author: "Ruben Dias",
      updated: "29/08/23",
      noteBody: "Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
    },
    {
      noteTitle: "Pricing",
      author: "Martin Curtis",
      updated: "26/08/23",
      noteBody: "Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
    },
    {
      noteTitle: "About creators/authors",
      author: "Olia Gozha",
      updated: "24/08/23",
      noteBody: "Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
    },
    {
      noteTitle: "Ideal customer",
      author: "Emerson Dorwart",
      updated: "24/08/23",
      noteBody: "Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
    },
    {
      noteTitle: "Industries",
      author: "Roman Kozak",
      updated: "21/08/23",
      noteBody: "Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
    },
    {
      noteTitle: "About Alfred",
      author: "Olia Gozha",
      updated: "20/08/23",
      noteBody: "Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
    },
    {
      noteTitle: "Pricing",
      author: "Martin Curtis",
      updated: "20/08/23",
      noteBody: "Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
    },
    {
      noteTitle: "Industries",
      author: "Olia Gozha",
      updated: "19/08/23",
      noteBody: "Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
      
    },
  ],
  searchPlaceholder: "Search notes…"
}

export const questionsTabData = {
  tableHead: ["Questions", "Highlight", "Author", "Updated"],
  tableRows: [
    {
      question: "Is it possible to change widget design?",
      highlight: true,
      author: "Olia Gozha",
      updated: "21/08/23",
      answer: "Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
    },
    {
      question: "How do I add Alfred to my website?",
      highlight: true,
      author: "Olia Gozha",
      updated: "21/08/23",
      answer: "Some text over here as a note body about design. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
    },
    {
      question: "Is it possible to change widget design?",
      highlight: false,
      author: "Olia Gozha",
      updated: "21/08/23",
      answer: "Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
    },
    {
      question: "How do I add Alfred to my website?",
      highlight: false,
      author: "Olia Gozha",
      updated: "21/08/23",
      answer: "Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
    },
    {
      question: "Is it possible to change widget design?",
      highlight: true,
      author: "Olia Gozha",
      updated: "21/08/23",
      answer: "Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
    },
    {
      question: "Can I have Alfred for multiple websites?",
      highlight: false,
      author: "Olia Gozha",
      updated: "21/08/23",
      answer: "Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
    },
    {
      question: "What CMSs is it compatible with?",
      highlight: false,
      author: "Olia Gozha",
      updated: "21/08/23",
      answer: "Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
    },
    {
      question: "How do I add Alfred to my website?",
      highlight: false,
      author: "Olia Gozha",
      updated: "21/08/23",
      answer: "Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row. Some text over here as a note body. It is displayed as preview when clicked on the arrow icon on the right of this table row."
    },
  ],
  searchPlaceholder: "Search questions…"
}

export const classes = {
  firstName: ""
}