import React, { useEffect, useState, useReducer } from "react";
import { Link } from "react-router-dom";

import Accordion from "../Accordion/Accordion";
import Input from "../BasicElements/Input/Input";
import Button from "../BasicElements/Button/Button";
import HighlightQuestions from "./HighlightQuestions";
import Loader from "../Loader/Loader";

import { contentData } from "../../pages/data/widgetData";
import { useGetWidgetFields, useSaveWidgetLabels } from "./widget.hook";

function Content() {
  return (
    <section>
      <Accordion title="Highlights">
        <QuestionsOrder />
      </Accordion>
      <Accordion title="Text elements">
        <WidgetLabels />
      </Accordion>
    </section>
  );
}

function QuestionsOrder() {
  return (
    <>
      <p className="text-sm max-w-[460px] py-2xl">
        {contentData.infoText}
        <span> to </span>
        <Link
          to="/knowledge/questions"
          className="text-accent-secondary-d01 transition-colors duration-300 hover:text-neutral-dark"
        >
          Knowledge: Questions
        </Link>
        .
      </p>
      <HighlightQuestions />
    </>
  );
}

function WidgetLabels() {
  const [saving, setSaving] = useState(false);

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "INIT":
        return [...action.fields];
      case "UPDATE_FIELD":
        return state.map(field => {
          if (field.name === action.name) {
            return {
              ...field,
              value: action.value
            };
          }
          return field;
        });
      default:
        return state;
    }
  }, []);

  const {
    loading,
    error,
    labels: labelsData,
    fields: fieldsSchema
  } = useGetWidgetFields();

  const { saveLabels } = useSaveWidgetLabels();

  useEffect(() => {
    if (fieldsSchema?.length && !loading) {
      const fieldsWithData = fieldsSchema.map(field => {
        const label = labelsData?.find(l => l.name === field.name);
        return {
          ...field,
          value: label?.value ?? field.default
        };
      });

      dispatch({ type: "INIT", fields: fieldsWithData });
    }
  }, [labelsData, fieldsSchema, loading]);

  const onFormSubmit = async e => {
    e.preventDefault();
    setSaving(true);
    const formData = new FormData(e.target);
    const labels = Array.from(formData.entries()).map(([name, value]) => ({
      name,
      value
    }));
    await saveLabels(labels);
    setSaving(false);
  };

  if (loading) return <Loader />;

  const fields = state;
  const fieldsElements = fields.map((field, index) => {
    return (
      <Input
        key={index}
        type={field.type}
        name={field.name}
        id={field.name}
        placeholder={field.placeholder}
        required={field.required}
        value={field.value}
        label={field.label}
        helpText={field.helpText}
        onChange={e => {
          dispatch({
            type: "UPDATE_FIELD",
            name: field.name,
            value: e.target.value
          });
        }}
      />
    );
  });

  return (
    <form className="py-2xl max-w-xl" onSubmit={onFormSubmit}>
      {fieldsElements}

      <Button disabled={saving}>Save</Button>
    </form>
  );
}

export default Content;
