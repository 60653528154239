import React from "react";

function Progress({ max = 100, value = 0 }) {
  const styles = {
    width: `${Math.round((value / max) * 100)}%`
  };

  return (
    <div className="w-full bg-gray-200 rounded-full h-1.5 bg-neutral-light-d01 mb-3xs">
      <div
        className="bg-accent-secondary h-1.5 rounded-full"
        style={styles}
      ></div>
    </div>
  );
}

export default Progress;
