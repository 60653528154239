import { useQuery, useMutation } from "@apollo/client";
import {
  updateAutoVoiceGenerationMutation,
  updateAutoAnswerGenerationMutation,
  updateWebsiteDomainMutation
} from "../../graphql/website";
import { profileQuery } from "../../graphql/profile";
import { useGetCurrentWebsiteId } from "../../hooks/index";

export const useUpdateAutoVoiceGeneration = () => {
  const webId = useGetCurrentWebsiteId();

  const [updateAutoVoiceGeneration, { loading, error, data }] = useMutation(
    updateAutoVoiceGenerationMutation,
    {
      variables: { webId },
      skip: !webId,
      refetchQueries: [{ query: profileQuery }]
    }
  );

  return {
    updateAutoVoiceGeneration: isAuto =>
      updateAutoVoiceGeneration({ variables: { isAuto } }),
    loading,
    error,
    data
  };
};

export const useUpdateAutoAnswerGeneration = () => {
  const webId = useGetCurrentWebsiteId();

  const [updateAutoAnswerGeneration, { loading, error, data }] = useMutation(
    updateAutoAnswerGenerationMutation,
    {
      variables: { webId },
      skip: !webId,
      refetchQueries: [{ query: profileQuery }]
    }
  );

  return {
    updateAutoAnswerGeneration: isAuto =>
      updateAutoAnswerGeneration({ variables: { isAuto } }),
    loading,
    error,
    data
  };
};

export const useUpdateWebsiteDomain = () => {
  const webId = useGetCurrentWebsiteId();

  const [updateWebsiteDomain, { loading, error, data }] = useMutation(
    updateWebsiteDomainMutation,
    {
      variables: { webId },
      skip: !webId,
      refetchQueries: [{ query: profileQuery }]
    }
  );

  return {
    updateWebsiteDomain: domain =>
      updateWebsiteDomain({ variables: { domain } }),
    loading,
    error,
    data
  };
};
