import React, { useState, useEffect, useRef } from "react";
import { useRoutes, Link } from "react-router-dom";
import Button from "../BasicElements/Button/Button";
import Search from "./Search/Search";
import Notifications from "./Notifications/Notifications";
import SidebarToggle from "../Sidebar/SidebarToggle/SidebarToggle";
import LockIcon from "../Icons/Lock";
import PlusIcon from "../Icons/Plus";
import classes from "./pageHeaderClasses";

const Routes = () => {
  return useRoutes([
    {
      path: "/overview/*",
      element: (
        <PageTitle
          title="Overview"
          tooltipText="All the latest updates, notifications, and stuff that require immediate attention."
        />
      )
    },
    {
      path: "/queries/*",
      children: [
        { path: "cache/*", 
          element: <PageTitle
            title="Queries: Cache"
            tooltipText="A register of all the questions from your Knowledge asked by the users."
          /> 
        },
        { path: "no-answer/*", 
          element: <PageTitle
            title="Queries: Without answer"
            tooltipText="A register of new questions that weren't answered in Knowledge or there is not enough information to generate an AI-generated answer."
          />
        }
      ]
    },
    {
      path: "/knowledge/*",
      children: [
        { path: "notes/*", 
          element: <PageTitle
            title="Knowledge: Notes"
            tooltipText="A database of raw materials about your business for AI to use as a foundation to generate answers coming from users."
          /> 
        },
        { path: "questions/*", 
          element: <PageTitle
            title="Knowledge: Questions"
            tooltipText="A database of already-defined questions in a question-answer format where answers can be written manually or generated automatically based on Notes."
          />
        }
      ]
    },
    {
      path: "/contacts/*",
      children: [
        { path: "all/*", 
          element: <PageTitle
            title="Contacts: All"
            tooltipText="A list of leads who left an email within the Alfred widget."
          /> 
        },
        { path: "subscribers/*", 
          element: <PageTitle
            title="Contacts: Subscribers"
            tooltipText="A list of new requests from users waiting to be contacted back with an answer to their question."
          />
        }
      ]
    },
    {
      path: "/widget/*",
      children: [
        { path: "installation/*", 
          element: <PageTitle
            title="Widget: Installation"
            tooltipText="Everything you need to install the Alfred widget onto your page."
          /> 
        },
        { path: "content/*", 
          element: <PageTitle
            title="Widget: Content"
            tooltipText="A place to edit text elements within the Alfred widget."
          />
        },
        { path: "design/*", 
          element: <PageTitle
            title="Widget: Design"
            tooltipText="A place to add custom CSS to change how the widget looks and feels."
          />
        },
        { path: "demo/*", 
          element: <PageTitle
            title="Widget: Demo"
            tooltipText="A preview of the widget to test how it will look on the page."
          />
        }
      ]
    },
    {
      path: "/webpage/*",
      element: (
        <PageTitle
          title="Webpage"
          tooltipText="A place to store everything connected to a stand-alone FAQ page."
        />
      )
    },
    {
      path: "/insights/*",
      element: (
        <PageTitle
          title="Insights"
          tooltipText="A report on how users interact with the widget on the page."
        />
      )
    },
    {
      path: "/settings/*",
      element: (
        <PageTitle
          title="Settings"
          tooltipText="Everything related to the general settings of the system."
        />
      )
    },
    {
      path: "/profile/*",
      element: (
        <PageTitle
          title="Profile"
          tooltipText="Information on your profile and subscription."
        />
      )
    }
  ]);
};

function PageTitle({ title, tooltipText }) {
  const toggleClass = "hidden";
  const [tooltipClasses, setTooltipClasses] = useState(toggleClass);

  const handleToolTipOn = e => {
    setTooltipClasses(classes.tooltipBox);
  };

  const handleToolTipOff = e => {
    setTooltipClasses(toggleClass);
  };

  return (
    <>
      <h2>{title}</h2>
      <button
        className={classes.tooltip}
        onMouseEnter={handleToolTipOn}
        onMouseLeave={handleToolTipOff}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="currentcolor"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.99902 1.99805C5.13249 1.99805 1.99805 5.13249 1.99805 8.99902C1.99805 12.8656 5.13249 16 8.99902 16C12.8656 16 16 12.8656 16 8.99902C16 5.13249 12.8656 1.99805 8.99902 1.99805ZM8.25028 5.9986C8.25028 5.58439 8.58606 5.2486 9.00028 5.2486H9.00778C9.42199 5.2486 9.75778 5.58439 9.75778 5.9986C9.75778 6.41282 9.42199 6.7486 9.00778 6.7486H9.00028C8.58606 6.7486 8.25028 6.41282 8.25028 5.9986ZM9.00028 8.24944C9.41449 8.24944 9.75028 8.58523 9.75028 8.99944V11.9994C9.75028 12.4137 9.41449 12.7494 9.00028 12.7494C8.58606 12.7494 8.25028 12.4137 8.25028 11.9994V8.99944C8.25028 8.58523 8.58606 8.24944 9.00028 8.24944Z"
          />
        </svg>
        <div className={tooltipClasses}>{tooltipText}</div>
      </button>
    </>
  );
}

function ButtonOptions() {
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const optionsRef = useRef(null);

  const handleButtonOptions = () => {
    setIsOptionsVisible(!isOptionsVisible);
  };

  useEffect(() => {
    function handleDocumentClick(event) {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setIsOptionsVisible(isOptionsVisible);
      }
    }

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <div className="relative" ref={optionsRef}>
      <Button
        type="circular"
        className="relative"
        onClick={handleButtonOptions}
      >
        <PlusIcon />
      </Button>
      {isOptionsVisible && (
        <ul className={classes.buttonOptionsBox}>
          <li>
            <Link to='/knowledge/notes/add' className={classes.buttonOption}>
              <span>New note</span>
            </Link>
          </li>
          <li>
            <Link to='/knowledge/questions/add' className={classes.buttonOption}>
              <span>New question</span>
            </Link>
          </li>
          <li>
            <Link
              to='/contacts/add'
              className={`${classes.buttonOption} ${classes.buttonOptionDisabled}`}
            >
              <div className={classes.buttonOptionIcon}>
                <LockIcon width={14} height={14} />
              </div>
              <span className="pl-4xs inline-block">New contact</span>
            </Link>
          </li>
          <li>
            <Link
              to='/'
              className={`${classes.buttonOption} ${classes.buttonOptionDisabled}`}
            >
              <div className={classes.buttonOptionIcon}>
                <LockIcon width={14} height={14} />
              </div>
              <span className="pl-4xs inline-block">New website</span>
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
}

function PageHeader({ handleSidebarToggle, toggleStatePageHeader, handleNewItem }) {
  const titleClasses = toggleStatePageHeader
    ? `${classes.title} translate-x-[-3rem]`
    : classes.title;

  return (
    <header className={classes.header}>
      <div className="flex items-center">
        <SidebarToggle
          togglePlacement="PageHeader"
          toggleState={toggleStatePageHeader}
          handleSidebarToggle={handleSidebarToggle}
        />
        <div className={titleClasses}>
          <Routes />
        </div>
      </div>
      {/* Actions */}
      <div className={classes.actions}>
        {/* Search */}
        {/* <Search className="mr-4xs" /> */}
        {/* Notifications */}
        {/* <Notifications className="mr-xs" /> */}
        {/* Plus button */}
        <ButtonOptions />
      </div>
    </header>
  );
}

export default PageHeader;
