import { gql } from "@apollo/client";

export const widgetCodeQuery = gql`
  query WidgetCode($webId: ID!) {
    installationCode(webId: $webId) {
      code
      src
    }
  }
`;

export const featuredQuestionsQuery = gql`
  query FeaturedQuestions($webId: ID!, $isFeatured: Boolean) {
    widget(webId: $webId) {
      featuredQuestionIds
    }
    questions(webId: $webId, isFeatured: $isFeatured) {
      answer
      id
      isFeatured
      question
    }
  }
`;

export const widgetCSSQuery = gql`
  query Widget($webId: ID!) {
    widget(webId: $webId) {
      css
    }
  }
`;

export const saveWidgetCSSMutation = gql`
  mutation saveWidgetCSS(
    $webId: ID!
    $css: css_String_NotNull_maxLength_50000!
  ) {
    saveWidgetCSS(webId: $webId, css: $css)
  }
`;

export const saveFeaturedQuestionsOrderMutation = gql`
  mutation saveFeaturedQuestionsOrder($webId: ID!, $ids: [ID!]!) {
    saveFeaturedQuestionsOrder(webId: $webId, ids: $ids)
  }
`;

export const widgetFieldsQuery = gql`
  query WidgetFields($webId: ID!) {
    widget(webId: $webId) {
      labels {
        value
        name
      }
    }
    fields(webId: $webId) {
      default
      placeholder
      helpText
      label
      name
      required
      type
    }
  }
`;

export const saveWidgetLabelsMutation = gql`
  mutation saveWidgetLabels($webId: ID!, $labels: [WidgetLabelInput!]!) {
    saveWidgetLabels(webId: $webId, labels: $labels) {
      name
      value
    }
  }
`;
