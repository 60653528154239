import React, { useState, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import TableSearch from "./TableSearch";
import NotesTableRow from "./NotesTableRow";
import TableHead from "./TableHead";
import Button from "../BasicElements/Button/Button";
import { notesTabData } from "../../pages/data/knowledgeData";
import Loader from "../Loader/Loader";
import { useGetNotes, useDeleteNote } from "./notes.hook";

const NoteItem = ({ note }) => {
  const Navigate = useNavigate();
  const { pathname } = useLocation();

  const [isDeleting, setIsDeleting] = useState(false);
  const { deleteNote } = useDeleteNote();

  const deleteHandler = async id => {
    setIsDeleting(true);
    await deleteNote({
      id
    });
    setIsDeleting(false);
  };

  return (
    <li
      id={note.id}
      className="m-3xl block w-full max-w-4xl rounded-lg bg-white p-2xl shadow-lg dark:bg"
    >
      <h3 className="text-2xl font-bold mb-sm">
        <Link to={`${pathname}/${note.id}`}>{note.title}</Link>
      </h3>
      <p className="mb-sm text-base">
        {note.body?.length > 100 ? note.body?.slice(0, 100) + "..." : note.body}
      </p>
      <div className="pt-sm">
        <p className="text-sm my-sm">
          Updated: {new Date(note.updatedAt?.seconds * 1000).toLocaleString()}
        </p>

        <p className="text-sm my-sm">Author: {note.user?.name}</p>
      </div>
      <div className="flex items-center justify-left">
        <button
          onClick={() => {
            Navigate(`/${note.id}`, {
              relative: "path"
            });
          }}
        >
          edit
        </button>
        <button onClick={() => deleteHandler(note.id)}>
          {isDeleting ? "deleting..." : "delete"}
        </button>
      </div>
    </li>
  );
};

const NotesList = () => {
  const { loading, notes } = useGetNotes();
  const Navigate = useNavigate();

  // Search functionality
  const [searchInput, setSearchInput] = useState("");
  const handleInputChange = e => {
    setSearchInput(e.target.value);
  };

  const sorted = useMemo(() => {
    if (notes?.length) {
      return Array.from(notes)?.sort((a, b) => {
        return b.updatedAt.seconds - a.updatedAt.seconds;
      });
    }

    return notes;
  }, [notes]);

  const searchResult = sorted?.filter(row => {
    const noteTitle = row.title.toLowerCase();
    const noteBody = row.body.toLowerCase();
    return (
      noteTitle.includes(searchInput.toLowerCase()) ||
      noteBody.includes(searchInput.toLowerCase())
    );
  });
  const tableRows = searchInput ? searchResult : sorted;
  const rowLimit = 5;
  const rowPerView = 10;
  const showLoadMore =
    tableRows?.length > rowLimit && tableRows?.length > rowPerView;

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col items-center justify-center">
      {/* <Button variant="outlined">
        <Link to="/knowledge/add">Add a new note</Link>
      </Button> */}

      {/* <ul className="flex flex-col items-center justify-center max-w-3xl">
        {sorted?.map(note => (
          <NoteItem key={note.id} note={note} />
        ))}
      </ul> */}
      {/* Table */}
      <div className="w-full bg-neutral-light-l01 rounded-md pb-md mb-lg">
        <TableSearch
          searchPlaceholder={notesTabData.searchPlaceholder}
          value={searchInput}
          onChange={handleInputChange}
        />
        {/* Body */}
        <div className="overflow-scroll px-0">
          {tableRows?.length > 0 ? (
            <table className="w-full min-w-max table-auto text-left">
              <TableHead tableHeadTitles={notesTabData.tableHead} />
              <tbody>
                {tableRows.map(
                  ({ id, title, body, author, updatedAt }, index) => {
                    const isLast = index === tableRows.length - 1;
                    const tdClasses = isLast ? "border-transparent" : "";

                    return (
                      <NotesTableRow
                        tdClasses={tdClasses}
                        key={index}
                        title={title}
                        body={body}
                        author={author}
                        updatedAt={updatedAt}
                        onEdit={() => {
                          Navigate(`./${id}`, {
                            relative: "path"
                          });
                        }}
                        onDelete={() => {
                          Navigate(`./${id}`, {
                            relative: "path"
                          });
                        }}
                        onTitleClick={() => {
                          Navigate(`./${id}`, {
                            relative: "path"
                          });
                        }}
                      />
                    );
                  }
                )}
              </tbody>
            </table>
          ) : (
            <p className="text-center p-4 text-neutral-dark-l02">
              No matches found for{" "}
              <span className="text-neutral-dark font-medium">
                "{searchInput}"
              </span>
            </p>
          )}
        </div>
        {/* Footer */}
        {showLoadMore && (
          <footer className="flex items-center justify-center p-4">
            <Button type="tertiary" size="sm">
              Load more
            </Button>
          </footer>
        )}
      </div>
    </div>
  );
};

export default NotesList;
