import React, { useState } from "react";
import DashboardRoutes from "../../routes";
import Sidebar from "../Sidebar/Sidebar";
import PageHeader from "../PageHeader/PageHeader";

const Dashboard = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const handleSidebarToggle = e => {
    setSidebarToggle(!sidebarToggle);
  };
  
  return (
    <>
      <div className="flex w-full bg-neutral-light h-screen overflow-hidden">
        {/* Global navigation */}
        <Sidebar
          toggleStateSidebar={sidebarToggle}
          handleSidebarToggle={handleSidebarToggle}
        />
        {/* Main content for pages */}
        <main className="grow px-5xl overflow-auto">
          <PageHeader
            toggleStatePageHeader={sidebarToggle}
            handleSidebarToggle={handleSidebarToggle}
          />
          <DashboardRoutes />
        </main>
      </div>
    </>
  );
};

export default Dashboard;
