import React from "react";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import Button from "../BasicElements/Button/Button";
import ArrowBack from "../Icons/ArrowDown";

import { useGetContactById, useDeleteContact } from "./contacts.hook";

const ContactsItem = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { contact, loading } = useGetContactById(id);

  const { deleteContact } = useDeleteContact();

  const handleDelete = async id => {
    await deleteContact(id);
    navigate("/contacts");
  };

  if (loading) return <Loader />;

  if (!contact) return <div className="p-3xl">Not Found</div>;

  return (
    <section className="bg-neutral-light-l01 rounded p-4xl mb-2xl">
      {/* Back to all items */}
      <header>
        <Button 
          type="tertiary" 
          size="sm" 
          onClick={()=>{navigate("/contacts/all")}}
        >
          <ArrowBack className="rotate-90 mr-3xs" />
          Back to all contacts
        </Button>
      </header>
      <div className="py-3xl">
        <span className="text-neutral-dark-l03 text-sm block mb-xs">
          Added: {new moment(contact.createdAt.seconds * 1000).format("MMM DD, YYYY")}
        </span>
        <h3 className="font-medium text-2xl max-w-2xl mb-e4xs">{contact.email}</h3>
      </div>
      {/* Action buttons */}
      <footer className="md:flex">
        {/* <Button 
          onClick={null}
          className="mr-3xl"
        >
          Contact
        </Button>  */}
        <Button 
          type="tertiary" 
          size="sm"
          onClick={() => handleDelete(contact.id)}
        >
          Delete
        </Button>
      </footer>
    </section>
  );
};

export default ContactsItem;
