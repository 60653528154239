import React from "react";

function Insights({
  width = 20,
  height = 20,
  fill = "currentcolor",
  className
}) {
  return (
    <svg 
      className={className} 
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`} 
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1.75C9 1.33579 9.33579 1 9.75 1C14.7206 1 19 5.27944 19 10.25C19 10.6642 18.6642 11 18.25 11H9.75C9.33579 11 9 10.6642 9 10.25V1.75ZM10.5 2.53943V9.5H17.4606C17.0847 5.92944 14.0706 2.91528 10.5 2.53943ZM7.43924 2.11912C7.60256 2.49978 7.42637 2.94075 7.04571 3.10407C4.37107 4.25157 2.5 6.90813 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5C13.0919 17.5 15.7484 15.6289 16.8959 12.9543C17.0592 12.5736 17.5002 12.3974 17.8809 12.5608C18.2615 12.7241 18.4377 13.165 18.2744 13.5457C16.8989 16.7519 13.7128 19 10 19C5.02944 19 1 14.9706 1 10C1 6.28718 3.24809 3.10114 6.45429 1.72558C6.83495 1.56227 7.27593 1.73846 7.43924 2.11912Z"
      />
    </svg>
  );
}

export default Insights;