import React from "react";

const pageHeaderData = {
  header: "sticky bg-neutral-light flex items-center justify-between py-6 top-0",
  title: "text-lg text-neutral-dark-l02 flex items-center relative z-[1000]",
  tooltip: "cursor-text ml-e4xs mt-[0.1em] text-neutral-dark-l04 transition-colors duration-500 hover:text-neutral-dark relative",
  tooltipBox: "text-left text-xs absolute bg-neutral-light-l01 p-md text-neutral-dark-l01 rounded shadow-custom top-0 left-[110%] w-60 z-[1000]",
  actions: "flex items-center",
  actionsItem: "p-2xs rounded-full bg-transparent text-neutral-dark-l02 transition-colors duration-500 hover:text-neutral-dark hover:bg-neutral-light-d01",
  divider: "bg-neutral-light-d03 mr-md w-px h-4",
  buttonOptionsBox: "z-50 text-left text-xs absolute bg-neutral-light-l01 px-2xs py-lg text-neutral-dark-l01 rounded shadow-custom top-5xl right-0 w-44",
  buttonOption: "flex items-center block px-2xs hover:text-accent-primary rounded transition-colors duration-500 py-3xs text-sm font-normal",
  buttonOptionDisabled: "text-neutral-dark-l03 cursor-not-allowed pointer-events-none",
  buttonOptionIcon: "fill-current flex items-center justify-center p-[2px]",
  buttonOptionLabel: "pl-4xs inline-block"
}

export default pageHeaderData;