import { useEffect } from "react";

import {
  signInWithEmailLink,
  isSignInWithEmailLink,
  getAuth
} from "firebase/auth";

const useLoginCallback = onSignIn => {
  useEffect(() => {
    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");

      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }

      onSignIn();
      signInWithEmailLink(getAuth(), email, window.location.href)
        .then(result => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");

          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          //remove apiKey parameter from url query only
          // window.history.replaceState(
          //   {},
          //   document.title,
          //   window.location.pathname
          // );
        })
        .catch(error => {
          console.log(error);
          window.location.href = window.location.pathname;

          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, [window.location.href]);
};

export default useLoginCallback;
