import { useEffect, useState, useContext } from "react";
import { db, auth } from "../services/firebase";
import { signInWithCustomToken } from "firebase/auth";
import {
  doc,
  getDoc,
  collection,
  onSnapshot,
  query,
  where
} from "firebase/firestore";
import { useSelector } from "react-redux";

export const useGetUser = () => {
  const userProfile = useSelector(state => state.profile);

  return {
    currentWebsite: userProfile?.currentWebsite,
    error: userProfile?.error,
    loading: userProfile?.loading,
    org: userProfile?.org,
    user: userProfile?.user,
    websites: userProfile?.websites
  };
};

export const useGetCurrentWebsiteId = () => {
  const userProfile = useSelector(state => state.profile);
  const currentWebsiteId = userProfile?.currentWebsite?.id;
  return currentWebsiteId;
};

export const useGetPosts = onlyPublished => {
  const [posts, setPosts] = useState(null);

  const { user } = useContext(UserContext);
  const hubId = user?.currentPortal?.hubId;

  useEffect(() => {
    if (!hubId) return;

    const postsRef = collection(db, "hubs/" + hubId + "/posts");
    const q = onlyPublished
      ? query(postsRef, where("currentState", "==", "PUBLISHED"))
      : postsRef;

    const unsubscribe = onSnapshot(q, querySnapshot => {
      const posts = [];
      querySnapshot.forEach(doc => {
        posts.push(doc.data());
      });

      setPosts(posts);
    });

    return unsubscribe;
  }, [hubId]);

  return posts;
};

export const useGetGenerations = () => {
  const [generations, setGenerations] = useState([]);

  const { user } = useContext(UserContext);
  const hubId = user?.currentPortal?.hubId;

  useEffect(() => {
    if (!hubId) return;

    const postsRef = collection(db, "hubs/" + hubId + "/generations");
    const q = query(postsRef);
    const unsubscribe = onSnapshot(q, querySnapshot => {
      const posts = [];
      querySnapshot.forEach(doc => {
        posts.push(doc.data());
      });

      setGenerations(posts);
    });

    return unsubscribe;
  }, [hubId]);

  return generations;
};

export const useAuthUserByCode = oldUserSignedOut => {
  const [userData, setUser] = useState({
    user: null,
    error: null,
    loading: true
  });

  const code = new URLSearchParams(window.location.search).get("code");

  useEffect(() => {
    (async () => {
      if (!code || !oldUserSignedOut) return;

      try {
        const response = await fetch(
          `/api/hubspot/oauth/callback?code=${code}`
        );

        const { customToken, error } = await response.json();

        if (error) throw new Error(error);

        const userCredential = await signInWithCustomToken(auth, customToken);
        const user = userCredential.user;
        setUser({ user, error: null, loading: false });
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        setUser({ user: null, error: errorMessage, loading: false });
        console.log(errorCode, errorMessage);
      }
    })();
  }, [code, oldUserSignedOut]);

  return userData;
};

export const useAuthUserByCustomToken = oldUserSignedOut => {
  const [userData, setUser] = useState({
    user: null,
    error: null,
    loading: true
  });

  const token = new URLSearchParams(window.location.search).get("token");

  useEffect(() => {
    (async () => {
      if (!token || !oldUserSignedOut) return;

      try {
        const userCredential = await signInWithCustomToken(auth, token);
        const user = userCredential.user;
        setUser({ user, error: null, loading: false });
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        setUser({ user: null, error: errorMessage, loading: false });
        console.log(errorCode, errorMessage);
      }
    })();
  }, [token, oldUserSignedOut]);

  return userData;
};
