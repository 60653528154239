import { gql } from "@apollo/client";

export const Queries = gql`
  query Queries($webId: ID!) {
    queries(webId: $webId) {
      id
      query
      answer
      isAnswered
      normalizedQuery
      createdAt {
        seconds
        nanoseconds
      }
      updatedAt {
        nanoseconds
        seconds
      }
    }
  }
`;

export const AnswerLessQueries = gql`
  query AnswerLessQueries($webId: ID!) {
    answerLessQueries(webId: $webId) {
      id
      query
      answer
      isAnswered
      normalizedQuery
      createdAt {
        seconds
        nanoseconds
      }
      updatedAt {
        nanoseconds
        seconds
      }
    }
  }
`;

export const QueryById = gql`
  query QueryById($webId: ID!, $id: ID!) {
    query(webId: $webId, id: $id) {
      createdAt {
        nanoseconds
        seconds
      }
      id
      query
      answer
      isAnswered
      normalizedQuery
      updatedAt {
        nanoseconds
        seconds
      }
    }
  }
`;

export const DeleteQueryMutation = gql`
  mutation DeleteQuery($webId: ID!, $id: ID!) {
    deleteCache(webId: $webId, id: $id) {
      id
      isAnswered
      query
    }
  }
`;
