import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  KnowledgeBase,
  KnowledgeBaseById,
  CreateKnowledgeBase,
  UpdateKnowledgeBase,
  DeleteKnowledgeBase
} from "../../graphql/knowledge";
import { useGetCurrentWebsiteId } from "../../hooks/index";

export const useGetNotes = () => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data, refetch } = useQuery(KnowledgeBase, {
    variables: { webId },
    skip: !webId
  });

  const { notes } = data || {};

  return { notes, refetch, loading };
};

export const useGetNote = ({ id }) => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data, refetch } = useQuery(KnowledgeBaseById, {
    variables: { webId, id },
    skip: !webId
  });

  const { note } = data || {};

  return { note, refetch, loading };
};

export const useCreateNote = () => {
  const webId = useGetCurrentWebsiteId();

  const [createNote, { loading, error, data }] = useMutation(
    CreateKnowledgeBase,
    {
      variables: { webId },
      skip: !webId,
      refetchQueries: [{ query: KnowledgeBase, variables: { webId } }]
    }
  );

  return {
    createNote: ({ title, body }) =>
      createNote({ variables: { note: { title, body } } }),
    loading,
    error,
    data
  };
};

export const useUpdateNote = () => {
  const webId = useGetCurrentWebsiteId();

  const [updateNote, { loading, error, data }] = useMutation(
    UpdateKnowledgeBase,
    {
      variables: { webId },
      skip: !webId
    }
  );

  return {
    updateNote: ({ id, title, body }) =>
      updateNote({ variables: { id, note: { title, body } } }),
    loading,
    error,
    data
  };
};

export const useDeleteNote = () => {
  const webId = useGetCurrentWebsiteId();

  const [deleteNote, { loading, error, data }] = useMutation(
    DeleteKnowledgeBase,
    {
      variables: { webId },
      skip: !webId,
      refetchQueries: [{ query: KnowledgeBase, variables: { webId } }]
    }
  );

  return {
    deleteNote: ({ id }) => deleteNote({ variables: { id } }),
    loading,
    error,
    data
  };
};
