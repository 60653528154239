import React from "react";
import classes from "../sidebarClasses";

function SidebarToggle({togglePlacement, toggleState, handleSidebarToggle}) {

  let btnClasses;

  if (togglePlacement === "PageHeader") {
    btnClasses = `${classes.asideToggle} ${classes.asideToggleExpand}`;
    btnClasses = toggleState ? (`${btnClasses} ${classes.asideToggleDisabled}`) : btnClasses;
  } else if (togglePlacement === "Sidebar") {
    btnClasses = classes.asideToggle;
    btnClasses = toggleState ? btnClasses : (`${btnClasses} ${classes.asideToggleDisabled}`);
  }

  return (
    <button className={btnClasses} onClick={handleSidebarToggle}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentcolor">
        <path fillRule="evenodd" clipRule="evenodd" d="M1 5.75C1 4.23122 2.23122 3 3.75 3H16.25C17.7688 3 19 4.23122 19 5.75V14.25C19 15.7688 17.7688 17 16.25 17H3.75C2.23122 17 1 15.7688 1 14.25V5.75ZM6.5 15.5H16.25C16.9404 15.5 17.5 14.9404 17.5 14.25V5.75C17.5 5.05964 16.9404 4.5 16.25 4.5H6.5V15.5ZM5 4.5V15.5H3.75C3.05964 15.5 2.5 14.9404 2.5 14.25V5.75C2.5 5.05964 3.05964 4.5 3.75 4.5H5ZM11.7483 7.18944C12.0579 7.46463 12.0857 7.93869 11.8106 8.24827L10.9201 9.25L14.75 9.25C15.1642 9.25 15.5 9.58579 15.5 10C15.5 10.4142 15.1642 10.75 14.75 10.75L10.9201 10.75L11.8106 11.7517C12.0857 12.0613 12.0579 12.5354 11.7483 12.8106C11.4387 13.0857 10.9646 13.0579 10.6894 12.7483L8.68944 10.4983C8.43685 10.2141 8.43685 9.78589 8.68944 9.50173L10.6894 7.25173C10.9646 6.94214 11.4387 6.91425 11.7483 7.18944Z"/>
      </svg>
    </button>
  )
};

export default SidebarToggle;