import React, { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import { Link } from "react-router-dom";
import Button from "../BasicElements/Button/Button";
import Loader from "../Loader/Loader";
import { validate } from "../../../node_modules/csstree-validator/dist/csstree-validator.esm.js";

import { useGetCSS, useSaveCSS } from "../WidgetSettings/widget.hook.js";

function StyleEditor() {
  const { css: cssCode, loading } = useGetCSS();
  const { saveCSS } = useSaveCSS();

  const [cssErrors, setCSSErrors] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [customCode, setCustomCode] = useState(
    `/* Add custom CSS below to stylize widget on your site */
    \n/* Main classes to target elements are:
    .alfred__title
    .alfred__label
    .alfred__highlights
    .alfred__highlights-list
    .alfred__highlights-item
    .alfred__highlights-item-header
    .alfred__highlights-item-icon
    .alfred__highlights-item-title
    .alfred__highlights-item-text
    .alfred__form
    .alfred__input
    .alfred__subscribe-form
    .alfred__button
    .alfred__results
    .alfred__answer-title
    .alfred__answer-text
    .alfred__audio
    */
    `
  );

  useEffect(() => {
    if (loading) return;
    if (!cssCode) return;
    setCustomCode(cssCode);
  }, [cssCode, loading]);

  useEffect(() => {
    if (!customCode) return;
    const errors = validate(customCode);
    errors && console.log(errors);
    setCSSErrors(errors);
  }, [customCode]);

  const onSave = async () => {
    console.log({ cssErrors });
    if (cssErrors?.length) {
      setShowErrors(true);
      return;
    }

    setShowErrors(false);
    setIsSaving(true);

    await saveCSS(customCode);
    setIsSaving(false);
  };

  useEffect(() => {
    const handleSave = e => {
      if ((e.metaKey && e.keyCode === 83) || (e.ctrlKey && e.keyCode === 83)) {
        e.preventDefault();
        onSave();
      }
    };
    document.addEventListener("keydown", handleSave);
    return () => {
      document.removeEventListener("keydown", handleSave);
    };
  }, [customCode, cssErrors]);

  if (loading) {
    return <Loader />;
  }

  return (
    <section>
      <div className="max-w-3xl mb-xl rounded-md overflow-hidden">
        <Editor
          language={"css"}
          height={"65vh"}
          value={customCode}
          theme={"vs-dark"}
          onChange={value => {
            setCustomCode(value);
          }}
        />
        {showErrors && !!cssErrors?.length && (
          <div className="mt-sm">
            <h2 className="text-xl">Errors</h2>
            <ul>
              {cssErrors.map((error, index) => {
                return (
                  <li key={index}>
                    {error.message} at line {error.line}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      <div className="sm:flex items-center justify-between max-w-3xl mb-3xl">
        <div className="flex items-center">
          <Button className="mr-xs" disabled={isSaving} onClick={onSave}>
            {isSaving ? "Saving..." : "Save"}
          </Button>
          {/* <Button type="secondary" className="mr-xs">
            Preview
          </Button> */}
        </div>
        <Link
          to="mailto:support@kohorta.co?subject=Alfred: Need help with widget installation"
          className="text-sm inline-block p-emd font-medium text-neutral-dark-l03 transition-colors duration-500 hover:text-neutral-dark"
        >
          Having troubles? Ask for help
        </Link>
      </div>
    </section>
  );
}

export default StyleEditor;
