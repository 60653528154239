import React from "react";

function Bolt({
  width = 20,
  height = 20,
  fill = "currentcolor",
  className
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`} 
      fill={fill}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2615 1.04712C11.5832 1.16679 11.7826 1.48959 11.7456 1.83084L11.1856 7.00007H16.25C16.5266 7.00007 16.7807 7.1523 16.9113 7.39617C17.0418 7.64004 17.0275 7.93595 16.874 8.16609L9.87404 18.6661C9.68429 18.9507 9.32601 19.0709 9.00302 18.9582C8.68003 18.8456 8.47419 18.5287 8.50259 18.1878L8.9349 13.0001H3.75C3.47121 13.0001 3.21543 12.8454 3.08588 12.5986C2.95634 12.3517 2.97442 12.0533 3.13283 11.8239L10.3828 1.32392C10.5779 1.04146 10.9398 0.927453 11.2615 1.04712ZM5.17927 11.5001H9.75C9.95974 11.5001 10.1599 11.5879 10.3019 11.7422C10.4439 11.8966 10.5148 12.1033 10.4974 12.3124L10.2389 15.4147L14.8486 8.50007H10.35C10.137 8.50007 9.9341 8.40952 9.79184 8.25102C9.64959 8.09253 9.58143 7.88102 9.60436 7.66929L9.93562 4.61157L5.17927 11.5001Z" />
    </svg>
  );
}

export default Bolt;

