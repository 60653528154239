import { gql } from "@apollo/client";

export const Activities = gql`
  query Activities($webId: ID!) {
    activities(webId: $webId) {
      id
      action
      createdAt {
        seconds
        nanoseconds
      }
      updatedAt {
        nanoseconds
        seconds
      }
    }
  }
`;

export const ActivityById = gql`
  query ActivityById($webId: ID!, $id: ID!) {
    activity(webId: $webId, id: $id) {
      createdAt {
        nanoseconds
        seconds
      }
      id
      action
      updatedAt {
        nanoseconds
        seconds
      }
    }
  }
`;
