import React, { useMemo } from "react";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import Loader from "../Loader/Loader";

import { useGetQueries } from "./queries.hook";

const Queries = () => {
  const { queries, loading } = useGetQueries();
  const location = useLocation();
  const { pathname } = location;
  console.log(queries);

  const filteredQueries = queries?.filter((query) => query.isAnswered);
  const sorted = useMemo(() => {
    if (filteredQueries?.length) {
      return Array.from(filteredQueries)?.sort((a, b) => {
        return b.updatedAt.seconds - a.updatedAt.seconds;
      });
    }

    return filteredQueries;
  }, [filteredQueries]);

  if (loading) return <Loader />;

  
  return (
    <section>
      {/* Meta info */}
      <p className="text-sm text-neutral-dark-l02">
      {(sorted?.length > 0) ? 
        <>
          <span className="font-bold text-neutral-dark">{sorted?.length}</span> new queries total.
        </>
        : "No cached queries yet."
      } 
      </p>
      {/* Queries list */}
      <ul className="py-4xl">
        {sorted?.map(query => (
          <li 
            key={query.id} 
            className="mb-xs"
          >
            <Link 
              to={`${pathname}/${query.id}`} 
              className="block bg-neutral-light-l01 rounded p-xl transition-colors duration-300 hover:bg-neutral-light-l01/60"
            >
              <span className="text-neutral-dark-l03 text-sm block mb-3xs">
                {new moment(query.createdAt.seconds * 1000).format("MMM DD, YYYY")}
              </span>
              <h5 className="font-medium">{query.query}</h5>
              <span className="text-sm text-neutral-dark-l02">{query.answer}</span>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Queries;
