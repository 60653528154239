import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "profile",
  initialState: {
    user: null,
    loading: true,
    error: null,
    org: null,
    websites: null,
    currentWebsite: null
  },
  reducers: {
    changeCurrentWebsite: (state, action) => {
      return {
        ...state,
        currentWebsite: state.websites.find(
          website => website.id === action.payload
        )
      };
    },
    resetProfile: state => {
      return {
        ...state,
        user: null,
        loading: false,
        error: null,
        org: null,
        websites: null,
        currentWebsite: null
      };
    },

    setProfile: (state, action) => {
      return {
        ...state,
        user: action.payload.user,
        loading: false,
        error: action.payload.error,
        org: action.payload.org,
        websites: action.payload.websites,
        currentWebsite: action.payload.currentWebsite
      };
    }
  }
});

export const { changeCurrentWebsite, setProfile, resetProfile } =
  counterSlice.actions;

export default counterSlice.reducer;
