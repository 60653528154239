import React, { useEffect } from "react";
import { Link, Navigate } from "react-router-dom";

// import { Navigate } from "react-router-dom";
import { auth } from "../services/firebase";
import useGetLoggedUser from "../hooks/get-auth-user.hook";

const SignOut = () => {
  const user = useGetLoggedUser();

  useEffect(() => {
    auth.signOut().catch(error => {
      console.error(error);
    });
  }, []);

  if (user) {
    return <div>Signing out...</div>;
  }
  return (
    <div>
      <div>Sign out successful</div>
      <Navigate to="/login" />
    </div>
  );
};

export default SignOut;
