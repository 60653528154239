import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import Loader from "../Loader/Loader";

import { useGetAnswerLessQueries } from "./queries.hook";

const ActivitiesList = () => {
  const { answerLessQueries, loading } = useGetAnswerLessQueries();
  const { pathname } = useLocation();

  console.log(answerLessQueries);

  const filteredQueries = answerLessQueries?.filter((query) => !query.isAnswered);

  const sorted = useMemo(() => {
    if (filteredQueries?.length) {
      return Array.from(filteredQueries)?.sort((a, b) => {
        return b.updatedAt.seconds - a.updatedAt.seconds;
      });
    }

    return filteredQueries;
  }, [filteredQueries]);

  if (loading) return <Loader />;
  return (
    <section>
      <p className="text-sm text-neutral-dark-l02">
      {(sorted?.length > 0) ? 
        <>
          <span className="font-bold text-neutral-dark">{sorted?.length}</span> queries without an answer total.
        </>
        : "No cached queries yet."
      } 
      </p>
      <ul className="py-4xl">
        {sorted?.map(query => (
          <li key={query.id} className="mb-xs">
            <Link 
              to={`${pathname}/${query.id}`} 
              className="block bg-neutral-light-l01 rounded p-xl transition-colors duration-300 hover:bg-neutral-light-l01/60"
            >
              <span className="text-neutral-dark-l03 text-sm block mb-xs">
                {new moment(query.createdAt.seconds * 1000).format("MMM DD, YYYY")}
              </span>
              <h5 className="font-medium">{query.query}</h5>
              <div className="text-sm text-neutral-dark-l02">{query.answer}</div>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ActivitiesList;
