import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../Loader/Loader";

import { useGetActivityById } from "./activities.hook";

const Activity = () => {
  const { id } = useParams();

  const { activity, loading } = useGetActivityById(id);

  if (loading) return <Loader />;
  return (
    <>
      <div className="p-xl">
        <Link to={`/activities/`}>Back to Activities</Link>
      </div>
      <div>{activity.action}</div>
    </>
  );
};

export default Activity;
