import React from "react";

function Textarea({
  label,
  helpText,
  placeholder,
  id,
  value,
  required,
  name,
  maxLength,
  className,
  onChange,
  customRef,
  defaultValue,
  readOnly
}) {
  const classes = {
    container: "flex flex-col w-full",
    label: "text-sm text-neutral-dark-l01 mb-esm",
    helpText: "block text-xs text-neutral-dark-l02",
    inputDefault:
      "bg-transparent text-md mr-sm mb-md p-md block border rounded border-neutral-light-d02",
    inputFocus:
      "focus:outline-none focus:border-neutral-light-d03 focus:ring-2 focus:ring-neutral-light-d02 focus:border-neutral-light-d02",
    inputDisabled:
      "disabled:bg-neutral-light disabled:text-neutral-light-d03 disabled:placeholder:text-neutral-light-d03 disabled:border-neutral-light-d01",
    inputInvalid:
      "invalid:border-accent-primary-l01 invalid:text-accent-primary-d03",
    inputFocusInvalid:
      "focus:invalid:border-accent-primary-l01 focus:invalid:ring-accent-primary-l03"
  };

  return (
    <div className={classes.container}>
      <span className={label ? classes.label : ""}>
        {label && <span>{label}</span>}
        {helpText && <span className={classes.helpText}>{helpText}</span>}
      </span>
      <textarea
        className={`${classes.inputDefault} ${classes.inputFocus} ${classes.inputDisabled} ${classes.inputInvalid} ${classes.inputFocusInvalid} ${className}`}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        id={id}
        required={required}
        name={name}
        maxLength={maxLength}
        defaultValue={defaultValue}
        ref={customRef}
        readOnly={readOnly}
      />
    </div>
  );
}

export default Textarea;
