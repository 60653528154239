import React from "react";

export const installationData = {
  infoText: "To install Alfred, add widget code to your website pages in places where you want to collect questions from users. Just copy code below and paste it into your page editor.",
  code: `<!-- Start of Alfred Widget Embed Code --> <script type="text/javascript" id="alfred-script-loader" async defer src="https://localhost:5000/widget/loader.js?code=997zddfKNBZlbFy6sn%2BScHVjibvjF%2BIeUgYZuBdWWIkDK41hsmjoZxOh2zwLGgXk"></script> <!-- End of Alfred Widget Embed Code -->`
}

export const contentData = {
  infoText: "These are the questions from Knowledge that will be displayed right away in the widget on the page. Drag and drop them below to reorder. To add or remove questions from Highlights go to ",
  highlightQuestions: [
    "How do I add Alfred to my website?",
    "What CMSs is it compatible with?",
    "Can I have Alfred for multiple websites?"
  ],
  textElements: {
    widgetTitle: "You ask. We answer.",
    askButtonLabel: "Ask",
    askButtonLabelLoading: "Asking…",
    inputFieldPlaceholder: "e.g. How does it work?",
    noAnswerNotification: "Great question! Currently, there are not enough materials for AI to generate an answer. However, you can subscribe to an answer, and we will contact you shortly.",
    subscribeButtonLabel: "Subscribe",
    subscribeButtonLabelLoading: "Subscribing…",
    successfulSubscriptionNotification: "Thank you for subscribing! We will write you back.",
  }
}

export const designData = {
  infoText: "To change the design of the widget, write custom CSS in the editor below to override default styles. To check the styles before applying them to the website, click the Preview button."
}