import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { contentData } from "../../pages/data/widgetData";
import DragDropIcon from "../Icons/DragDrop";

import {
  useGetFeaturedQuestions,
  useSaveFeaturedQuestionsOrder
} from "../WidgetSettings/widget.hook";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // change background color if dragging
  background: isDragging ? "#F3FAF7" : "",
  outline: isDragging ? "2px solid #D8EFE7" : "",
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // styles we need to apply on draggables
  ...draggableStyle
});

function HighlightQuestions() {
  const [items, setItems] = useState([]);
  const { questions, featuredQuestions, loading } = useGetFeaturedQuestions();
  const { saveQuestionsOrder } = useSaveFeaturedQuestionsOrder();

  useEffect(() => {
    if (!loading && featuredQuestions?.length) {
      setItems(featuredQuestions);
    }
  }, [featuredQuestions, loading]);

  const onDragEnd = result => {
    if (!result.destination) {
      // dropped outside the list
      return;
    }
    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(newItems);
    saveQuestionsOrder(newItems.map(question => question.id));
  };

  if (!items?.length) {
    return <div> There is no featured question yet.</div>;
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="max-w-[460px]"
              >
                {items?.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className="select-none p-4 mb-2 rounded bg-neutral-light outline:none"
                      >
                        <div className="flex items-center">
                          <DragDropIcon
                            className={`mr-3xs ${
                              snapshot.isDragging
                                ? "text-accent-secondary-d02"
                                : "text-neutral-light-d02 "
                            }`}
                          />
                          <span className={
                            snapshot.isDragging
                              ? "text-accent-secondary-d02"
                              : "text-neutral-dark "
                            }
                          >
                            {item.question}
                          </span>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default HighlightQuestions;
