import React from "react";

const sidebarData = {
  aside: "flex flex-col py-2xl shrink-0 bg-neutral-light h-screen",
  asideExpanded: "w-[240px]",
  asideMinimized: "w-[84px]",
  asideToggle: "translate-x-[0.05rem] p-2xs rounded-full bg-transparent text-neutral-dark-l02 transition-colors duration-500 hover:bg-neutral-light-d01 hover:text-neutral-dark",
  asideToggleExpand: "mr-xs scale-x-[-1] translate-x-[-0.3rem]",
  asideToggleDisabled: "cursor-not-allowed pointer-events-none opacity-0",
  websiteOptions: "flex items-center mx-sm text-xs p-md rounded border border-neutral-light-d02 text-neutral-dark-l02",
  navItem: "flex items-center bg-neutral-light block px-md rounded transition-colors duration-500",
  navItemHover: "hover:text-accent-primary",
  navItemIcon: "fill-current flex items-center justify-center p-[2px]",
  navItemLabel: "pl-xs inline-block",
  navItemParent: "w-full justify-between",
  navItemParentOpen: "text-neutral-dark-l02",
  navItemChild: "py-e2xs text-sm",
  navItemChildren: "pb-sm",
  navItemMain: "py-xs text-sm",
  navItemMeta: "py-2xs text-xs text-neutral-dark-l02",
  navItemActive: "bg-neutral-light-d01 cursor-not-allowed pointer-events-none",
  navItemDisabled: "text-neutral-dark-l03 cursor-not-allowed pointer-events-none",
  navItemComing: "relative after:content-['Coming'] after:absolute after:bg-accent-secondary-l03 after:text-accent-secondary-d03 after:text-2xs after:rounded after:py-e4xs after:px-e3xs after:top-[50%] after:translate-y-[-50%] after:right-0"
}

export default sidebarData;