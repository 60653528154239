import React, { useState } from "react";
import useLoginCallback from "./use-login-callback.hook";
import { auth } from "../../services/firebase.js";
import {
  GoogleAuthProvider,
  signInWithPopup,
  sendSignInLinkToEmail
} from "firebase/auth";

import Loader from "../Loader/Loader";

///////// Styles & Content /////////
import styles from "./Login.module.css";
import { loginData } from "./loginData";
////////////////////////////////////

const actionCodeSettings = {
  url: window.location.href,
  handleCodeInApp: true
};

const Login = () => {
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [loading, setLoading] = useState(false);
  useLoginCallback(() => setLoading(true));

  const signInWithEmail = async e => {
    e.preventDefault();
    await auth.signOut();

    const email = e.target[0].value;
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", email);
        setIsLinkSent(true);
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  const signInWithGoogle = async e => {
    e.preventDefault();
    await auth.signOut();

    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then(result => {
        setLoading(true);
        const user = result.user;
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className={`${styles.loginWrapper} flex h-screen justify-center content-center items-center`}
    >
      {/* Main elements */}
      <div>
        {/* Heading */}
        <h1 className={styles.loginHeading}>{loginData.mainHeading}</h1>

        {/* Sign in options */}
        <div className={styles.loginOptions}>
          {/* Google sing in */}
          <button
            className={`${styles.loginBtn} ${styles.loginBtnBrand}`}
            onClick={signInWithGoogle}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M22.4983 12.4297C22.4983 11.5908 22.4302 10.9786 22.2829 10.3438H12.7031V14.1303H18.3262C18.2129 15.0713 17.6007 16.4884 16.2402 17.4406L16.2212 17.5674L19.2501 19.9139L19.46 19.9348C21.3872 18.1549 22.4983 15.5361 22.4983 12.4297Z"
                fill="#4285F4"
              />
              <path
                d="M12.7029 22.407C15.4577 22.407 17.7704 21.5 19.4597 19.9355L16.24 17.4413C15.3784 18.0422 14.222 18.4617 12.7029 18.4617C10.0047 18.4617 7.71462 16.6818 6.89828 14.2217L6.77862 14.2318L3.62908 16.6693L3.58789 16.7838C5.26575 20.1168 8.7122 22.407 12.7029 22.407Z"
                fill="#34A853"
              />
              <path
                d="M6.89871 14.2211C6.68331 13.5862 6.55865 12.9059 6.55865 12.2031C6.55865 11.5001 6.68331 10.8199 6.88738 10.1851L6.88167 10.0499L3.69266 7.57324L3.58833 7.62287C2.8968 9.006 2.5 10.5592 2.5 12.2031C2.5 13.8469 2.8968 15.4 3.58833 16.7832L6.89871 14.2211Z"
                fill="#FBBC05"
              />
              <path
                d="M12.7029 5.94525C14.6188 5.94525 15.9112 6.77284 16.6481 7.46445L19.5277 4.65286C17.7592 3.009 15.4577 2 12.7029 2C8.7122 2 5.26575 4.29006 3.58789 7.62311L6.88694 10.1853C7.71462 7.72518 10.0047 5.94525 12.7029 5.94525Z"
                fill="#EB4335"
              />
            </svg>
            <span>{loginData.googleBtn}</span>
          </button>
          <p className={styles.optionsTextLabel}>
            {loginData.optionsTextLabel}
          </p>

          {/* Email sign in */}
          <form className={styles.loginInput} onSubmit={signInWithEmail}>
            <input type="email" placeholder={loginData.inputPlaceholder} />
            <button type="submit" className={styles.loginBtn}>
              {loginData.inputBtnLabel}
            </button>
          </form>
        </div>

        {/* Sent email notification */}
        {isLinkSent && (
          <div className={styles.notification}>
            <p>{loginData.sentEmailNotification}</p>
          </div>
        )}
      </div>

      {/* Brand element */}
      <div className={styles.brandElement}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.9993 0H0V31.9995H31.9993V0ZM25.5996 15.9998C19.1997 15.9998 15.9998 19.1999 15.9998 25.5996L6.40001 25.5998V16C12.7999 16 15.9998 12.7999 15.9998 6.40001H25.5996V15.9998Z"
            fill="#FF5925"
          />
        </svg>
      </div>
    </div>
  );
};

export default Login;
