import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import Button from "../BasicElements/Button/Button";
import ArrowHead from "../Icons/ArrowHead";

function NotesTableRow({
  tdClasses,
  title,
  body,
  author,
  updatedAt,
  onEdit,
  onDelete,
  onTitleClick
}) {

  const charLimit = 140;

  const date = moment(updatedAt?.seconds * 1000);

  return (
    <tr
      className="bg-neutral-light/0 transition-colors duration-300 hover:bg-neutral-light/60"
    >
      {/* Note */}
      <td
        className={`${tdClasses} p-4 font-normal text-sm pl-4xl w-2/3 border-b border-neutral-light-d01`}
      >
        <div className="flex gap-2 py-md">
          <div className="cursor-pointer" onClick={onTitleClick}>
            {/* <Link to={`${pathname}/${note.id}`}> */}
            <h6
              className="font-medium text-base"
            >
              {title}
            </h6>
            {/* </Link> */}
            <div>
              <p className="max-w-lg py-esm text-neutral-dark-l01">
                {charLimit < body?.length
                  ? body.slice(0, charLimit) + "…"
                  : body}
              </p>
              {/* <div>
                <Button type="tertiary" className="pl-0" onClick={onEdit}>
                  Edit
                </Button>
                <Button type="tertiary" className="pl-0" onClick={onDelete}>
                  Delete
                </Button>
              </div> */}
            </div>
          </div>
        </div>
      </td>
      {/* Author */}
      <td
        className={`${tdClasses} p-4 font-normal text-sm border-b border-neutral-light-d01`}
      >
        <div className="flex flex-col">
          <span>{author || "Unknown"}</span>
        </div>
      </td>
      {/* Updated */}
      <td
        className={`${tdClasses} p-4 font-normal text-sm border-b border-neutral-light-d01`}
      >
        <div className="w-max">
          <span>{date.format("MMM DD, YYYY")}</span>
        </div>
      </td>
    </tr>
  );
}

export default NotesTableRow;
