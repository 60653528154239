import React from "react";
import { Route, Routes } from "react-router-dom";
import MainNav from "../components/MainNav/MainNav";

import ActivitiesList from "../components/Activities/ActivitiesList";
import Activity from "../components/Activities/ActivityItem";

const ActivitiesPage = () => {
  return (
    <>
      <MainNav />

      <Routes>
        <Route path="/" element={<ActivitiesList />} />
        <Route path="/:id" element={<Activity />} />
      </Routes>
    </>
  );
};

export default ActivitiesPage;
