import React, { useState, useEffect } from "react";
import Input from "../BasicElements/Input/Input";

import IconButton from "../BasicElements/Button/IconButton";
import LinkIcon from "../Icons/Link";
import TagIcon from "../Icons/Tag";
import TrashIcon from "../Icons/Trash";
import ArrowRightUpIcon from "../Icons/ArrowRightUp";
import PlusIcon from "../Icons/Plus";

const References = ({ initialReferences = [], onChange }) => {
  const [urlLabel, setUrlLabel] = useState("");
  const [urlLink, setUrlLink] = useState("");
  const [isUrlLinkValid, setIsUrlLinkValid] = useState(false);
  const [urlLinkPrefix, setUrlLinkPrefix] = useState("https://");

  const [references, setReferences] = useState([]);

  useEffect(() => {
    if (initialReferences && initialReferences.length > 0) {
      setReferences(initialReferences);
    }
  }, [initialReferences]);

  const addReference = e => {
    e.preventDefault();

    if (!isUrlLinkValid) {
      return;
    }

    setReferences(references => [
      ...references,
      { title: urlLabel, url: urlLinkPrefix + urlLink }
    ]);
    onChange([
      ...references,
      { title: urlLabel, url: urlLinkPrefix + urlLink }
    ]);

    setUrlLabel("");
    setUrlLink("");
  };

  const removeReference = index => {
    setReferences(references => [
      ...references.slice(0, index),
      ...references.slice(index + 1)
    ]);
    onChange([...references.slice(0, index), ...references.slice(index + 1)]);
  };

  const onUrlChange = e => {
    let url = e.target.value;
    // remove https:// or http://
    url = url.replace(/^(?:https?:\/\/)?/i, "");

    // check if url is valid
    setIsUrlLinkValid(
      /^(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-?=%.]+$/i.test(url)
    );

    setUrlLink(url);
  };

  return (
    <>
      <fieldset>
        <span className="inline-block text-sm text-neutral-dark-l01 mb-esm">
          Related links
        </span>
        <div className="sm:flex items-center">
          <Input
            type="text"
            placeholder="Label"
            value={urlLabel}
            onChange={e => setUrlLabel(e.target.value)}
          >
            <TagIcon />
          </Input>

          <Input
            type="url"
            placeholder="example.com/section"
            value={urlLink}
            onChange={onUrlChange}
            valid={isUrlLinkValid}
          >
            <span className="sm:flex items-end">
              <LinkIcon />
              <span className="ml-4xs">https://</span>
            </span>
          </Input>

          <IconButton
            bg_color="neutral-light-d01"
            onClick={addReference}
            className="mb-md mr-sm"
          >
            <PlusIcon />
          </IconButton>
        </div>
      </fieldset>
      <ul className="list-none max-w-xl">
        {references.map((reference, index) => (
          <li
            key={index}
            className="bg-neutral-light rounded pl-lg pr-sm py-2xs text-md mb-3xs flex justify-between items-center"
          >
            <a
              className="flex items-center hover:underline"
              href={reference.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ArrowRightUpIcon className="mr-2xs" />
              {reference.title}
            </a>
            <IconButton
              onClick={e => {
                e.preventDefault();
                removeReference(index);
              }}
              icon_color="neutral-dark-l03"
            >
              <TrashIcon />
            </IconButton>
          </li>
        ))}
      </ul>
    </>
  );
};

export default References;
