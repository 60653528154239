import React from "react";

function Tag({
  width=20, 
  height=20, 
  fill='currentcolor', 
  className
}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill={fill}>
      <path fillRule="evenodd" clipRule="evenodd" d="M1 3.85572C1 2.27855 2.27855 1 3.85572 1H9.30067C10.0581 1 10.7844 1.30087 11.32 1.83642L18.1636 8.68002C19.2788 9.79525 19.2788 11.6034 18.1636 12.7186L12.7186 18.1636C11.6034 19.2788 9.79525 19.2788 8.68002 18.1636L1.83642 11.32C1.30087 10.7844 1 10.0581 1 9.30067V3.85572ZM3.85572 2.5C3.10698 2.5 2.5 3.10698 2.5 3.85572V9.30067C2.5 9.66023 2.64284 10.0051 2.89708 10.2593L9.74068 17.1029C10.2701 17.6324 11.1285 17.6324 11.658 17.1029L17.1029 11.658C17.6324 11.1285 17.6324 10.2701 17.1029 9.74068L10.2593 2.89708C10.0051 2.64283 9.66023 2.5 9.30067 2.5H3.85572ZM6.5 6C6.22386 6 6 6.22386 6 6.5C6 6.77614 6.22386 7 6.5 7C6.77614 7 7 6.77614 7 6.5C7 6.22386 6.77614 6 6.5 6ZM4.5 6.5C4.5 5.39543 5.39543 4.5 6.5 4.5C7.60457 4.5 8.5 5.39543 8.5 6.5C8.5 7.60457 7.60457 8.5 6.5 8.5C5.39543 8.5 4.5 7.60457 4.5 6.5Z" />
    </svg>
  )
};

export default Tag;