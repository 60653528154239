import React, { useState } from "react";
import Button from "../BasicElements/Button/Button";
import Input from "../BasicElements/Input/Input";
import AccordionHeader from "./AccordionHeader";

function Accordion({children, title}) {

  const [toggle, setToggle] = useState(true);
  const handleClick = (e) => {
    setToggle(!toggle);
  }

  return (
    <div className="bg-neutral-light-l01 rounded p-4xl mb-2xl">
      <AccordionHeader title={title} handleBtnToggle={handleClick} btnClassToggle={toggle} />
      <div className={toggle ? "" : "hidden"}>
        { children }
      </div>
    </div>
  )
};

export default Accordion;

