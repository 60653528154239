import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";

import { useGetActivities } from "./activities.hook";

const ActivitiesList = () => {
  const { activities, loading } = useGetActivities();

  const sortedAct = useMemo(() => {
    if (activities?.length) {
      return Array.from(activities)?.sort((a, b) => {
        return b?.updatedAt?.seconds - a?.updatedAt?.seconds;
      });
    }

    return activities;
  }, [activities]);

  if (loading) return <Loader />;
  return (
    <div>
      <ul className="p-xl">
        {sortedAct?.map(activity => (
          <li key={activity.id}>
            <Link to={`/activities/${activity.id}`}>{activity.action}</Link>
            <div className="text-muted">
              {new Date(activity.createdAt.seconds * 1000).toLocaleDateString()}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ActivitiesList;
