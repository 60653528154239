import React from "react";

function IconButton({
  children,
  bg_color = "transparent",
  icon_color="neutral-dark",
  className,
  onClick,
  disabled
}) {
  const states = {
    default:
      "flex items-center justify-center border font-medium p-e3xs rounded-full transition-all duration-500",
    hover: "hover:bg-neutral-light hover:border-neutral-light hover:text-neutral-dark",
    active: "active:bg-transparent",
    focus: "focus:outline-none focus:ring focus:ring-accent-secondary-l03"
  };
  let classes = `bg-${bg_color} border-${bg_color} text-${icon_color} ${states.default} ${states.hover} ${states.active} ${states.focus}`;

  classes = className ? `${classes} ${className}` : classes;

  return (
    <button
      className={classes}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default IconButton;
