import { gql } from "@apollo/client";

export const questionsQuery = gql`
  query Questions($webId: ID!, $isFeatured: Boolean) {
    questions(webId: $webId, isFeatured: $isFeatured) {
      id
      question
      answer
      isFeatured
      references {
        url
        title
      }
      createdAt {
        seconds
        nanoseconds
      }
      updatedAt {
        seconds
        nanoseconds
      }
    }
  }
`;

export const questionQueryId = gql`
  query QuestionId($webId: ID!, $id: ID!) {
    question(webId: $webId, id: $id) {
      id
      question
      answer
      isFeatured
      references {
        url
        title
      }
      createdAt {
        seconds
        nanoseconds
      }
      updatedAt {
        seconds
        nanoseconds
      }
    }
  }
`;

export const addQuestionQuery = gql`
  mutation AddQuestion($webId: ID!, $question: QuestionInput!) {
    addQuestion(webId: $webId, question: $question) {
      id
      question
      answer
      createdAt {
        seconds
        nanoseconds
      }
      updatedAt {
        seconds
        nanoseconds
      }
    }
  }
`;

export const updateQuestionQuery = gql`
  mutation UpdateQuestion($webId: ID!, $id: ID!, $question: QuestionInput!) {
    updateQuestion(webId: $webId, id: $id, question: $question) {
      id
    }
  }
`;

export const deleteQuestionQuery = gql`
  mutation DeleteQuestion($webId: ID!, $id: ID!) {
    deleteQuestion(webId: $webId, id: $id) {
      id
      question
      answer
      createdAt {
        seconds
        nanoseconds
      }
      updatedAt {
        seconds
        nanoseconds
      }
    }
  }
`;

export const GenerateAnswerMutation = gql`
  mutation GenerateAnswer(
    $webId: ID!
    $question: question_String_NotNull_maxLength_200!
  ) {
    generateAnswer(webId: $webId, question: $question) {
      answer
      isAnswered
    }
  }
`;
