import React from "react";
import { Navigate } from "react-router-dom";

import LoginComponent from "../components/Login/Login";
import Loader from "../components/Loader/Loader";
import { useGetUser } from "../hooks/index";

const Login = () => {
  const { user, loading: loadingCurrentUser } = useGetUser();

  if (loadingCurrentUser) {
    return <Loader />;
  }

  if (user) {
    return <Navigate to="/overview" />;
  } else {
    return <LoginComponent />;
  }
};

export default Login;
