import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useGetUser } from "../hooks/index";
import { useGetCode } from "../components/WidgetSettings/widget.hook";

import Button from "../components/BasicElements/Button/Button";
import Input from "../components/BasicElements/Input/Input";
import Textarea from "../components/BasicElements/Input/Textarea";
import BillingBtn from "../components/Billing/BillingBtn";
import Loader from "../components/Loader/Loader";
import WebsiteDomain from "../components/WebsiteSettings/WebsiteDomain";

const UpgradePlan = () => {
  return (
    <section className="p-4xl mb-2xl rounded bg-gradient-to-r from-[#E6E9DE] to-[#FB9777] from-30% to-150%">
      <h4 className="font-medium text-xl mb-xs">Turn on good stuff</h4>
      <div className="md:flex justify-between items-end">
        <ul className="list-disc px-xl mb-md md:mb-0">
          <li>
            automatic AI generation for answers based on{" "}
            <span className="font-medium">Notes</span>
          </li>
          <li>audio generation for answers</li>
          <li>
            more storage options in{" "}
            <span className="font-medium">Knowledge</span>,{" "}
            <span className="font-medium">Queries</span>, and{" "}
            <span className="font-medium">Contacts</span>
          </li>
        </ul>
        <div>
          <BillingBtn>Upgrade</BillingBtn>
        </div>
      </div>
    </section>
  );
};

const SetUpGuide = () => {
  const [copied, setCopied] = useState(false);
  const { code, loading } = useGetCode();
  const areaRef = useRef(null);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2500);
    }
  }, [copied]);

  if (loading) return <Loader />;

  return (
    <section className="p-4xl  mb-2xl rounded bg-neutral-light-l01">
      <h4 className="font-medium text-xl mb-md">Set up account</h4>
      {/* Website URL */}
      <section className="py-xl">
        <h5 className="font-medium text-lg mb-xs">
          Step 1 &mdash; Add website
        </h5>
        <p className="text-sm max-w-[600px] mb-sm">
          Type in URl of the website where you are planning to have Alfred
          installed.
        </p>
        <WebsiteDomain />
      </section>
      {/* Installation code */}
      <section className="py-xl">
        <h5 className="font-medium text-lg mb-xs">
          Step 2 &mdash; Install widget
        </h5>
        <p className="text-sm max-w-[600px] mb-sm">
          To install Alfred, add widget code to your website page in a place
          where you want to collect questions from users. Just copy code below
          and paste it into your page editor.
        </p>
        <form>
          <Textarea
            customRef={areaRef}
            value={code}
            readOnly={true}
            className="resize-none font-mono text-neutral-dark-l02 h-40 text-sm max-w-2xl"
          />
          <div className="sm:flex items-center justify-between max-w-2xl">
            <Button
              type="quaternary"
              onClick={() => {
                navigator.clipboard.writeText(areaRef.current.value);
                setCopied(true);
              }}
            >
              {copied ? "Copied!" : "Copy code"}
            </Button>
            <Link
              to="mailto:support@kohorta.co?subject=Alfred: Need help with widget installation"
              className="inline-flex items-center bg-transparent text-neutral-dark-l02 font-medium py-exs rounded transition-all duration-500 whitespace-nowrap hover:text-accent-secondary-d01 active:text-neutral-dark focus:outline-none focus:ring focus:ring-accent-secondary-l03 text-sm"
            >
              Having troubles? Ask for help
            </Link>
          </div>
        </form>
      </section>
    </section>
  );
};

const Dashboard = () => {
  const userProfile = useGetUser();

  const { websites, loading } = userProfile || {};

  const plan = websites?.[0]?.plan;
  const isPaidPlan = plan?.isPaid;

  if (loading) return <Loader />;

  return (
    <section>
      <h3 className="font-medium text-2xl mb-2xl">Welcome to Alfred!</h3>
      {!isPaidPlan && <UpgradePlan />}
      <SetUpGuide />
    </section>
  );
};

export default Dashboard;
