import React from "react";
import Questions from "../components/Questions/QuestionsTable";
import QuestionItem from "../components/Questions/QuestionItem";
import AddQuestion from "../components/Questions/AddQuestion";
import { Route, Routes } from "react-router-dom";
import MainNav from "../components/MainNav/MainNav";

const QuestionsPage = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Questions />} />
        <Route path="/add" element={<AddQuestion />} />
        <Route path="/:id" element={<QuestionItem />} />
      </Routes>
    </>
  );
};

export default QuestionsPage;
