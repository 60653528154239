import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  ApolloProvider,
  gql
} from "@apollo/client";
import Observable from "zen-observable";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const httpLink = new HttpLink({ uri: "/graphql" });

const authMiddleware = new ApolloLink(async (operation, forward) => {
  let user = getAuth().currentUser;

  // if no user, wait for one
  if (!user) {
    user = await new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        getAuth(),
        user => {
          unsubscribe();
          resolve(user);
        },
        reject
      );
    });
  }

  const token = await user?.getIdToken(true);

  if (!token) {
    return new Observable(subscriber => {
      subscriber.error(new Error("No token"));
    });
  }

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  }));

  return forward(operation);
});

const client = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false
  })
});

export default client;
