import { gql } from "@apollo/client";

export const ContactsQuery = gql`
  query Contacts($webId: ID!) {
    contacts(webId: $webId) {
      createdAt {
        seconds
        nanoseconds
      }
      email
      id
      updatedAt {
        seconds
        nanoseconds
      }
    }
  }
`;

export const ContactByIdQuery = gql`
  query ContactById($webId: ID!, $id: ID!) {
    contact(webId: $webId, id: $id) {
      createdAt {
        seconds
        nanoseconds
      }
      email
      id
      updatedAt {
        seconds
        nanoseconds
      }
    }
  }
`;

export const deleteContactMutation = gql`
  mutation DeleteContact($webId: ID!, $id: ID!) {
    deleteContact(webId: $webId, id: $id) {
      id
    }
  }
`;
