import React from "react";

function Profile({
  width = 20,
  height = 20,
  fill = "currentcolor",
  className
}) {
  return (
    <svg 
      className={className} 
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`} 
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.50049C8.61929 2.50049 7.5 3.61978 7.5 5.00049C7.5 6.3812 8.61929 7.50049 10 7.50049C11.3807 7.50049 12.5 6.3812 12.5 5.00049C12.5 3.61978 11.3807 2.50049 10 2.50049ZM6 5.00049C6 2.79135 7.79086 1.00049 10 1.00049C12.2091 1.00049 14 2.79135 14 5.00049C14 7.20963 12.2091 9.00049 10 9.00049C7.79086 9.00049 6 7.20963 6 5.00049ZM3 16.6255C3 13.5189 5.5184 11.0005 8.625 11.0005H11.375C14.4816 11.0005 17 13.5189 17 16.6255C17 17.9372 15.9367 19.0005 14.625 19.0005H5.375C4.06332 19.0005 3 17.9372 3 16.6255ZM8.625 12.5005C6.34683 12.5005 4.5 14.3473 4.5 16.6255C4.5 17.1087 4.89175 17.5005 5.375 17.5005H14.625C15.1082 17.5005 15.5 17.1087 15.5 16.6255C15.5 14.3473 13.6532 12.5005 11.375 12.5005H8.625Z"
      />
    </svg>
  );
}

export default Profile;