import React from "react";
import { Route, Routes } from "react-router-dom";

import MainNav from "../components/MainNav/MainNav";
import Notes from "../components/Notes/NotesList";
import AddNote from "../components/Notes/AddNote";
import NoteItem from "../components/Notes/NoteItem";

const NotesPage = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Notes />} />
        <Route path="/add" element={<AddNote />} />
        <Route path="/:id" element={<NoteItem />} />
      </Routes>
    </>
  );
};

export default NotesPage;
