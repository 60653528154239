import React from "react";

function Toggle({
  onChange, 
  checked, 
  disabled, 
  id, 
  name, 
  label,
  className
}) {
  const defaultToggleClasses = "w-11 h-6 bg-neutral-light-d02 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-accent-secondary-l03 rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-neutral-light-l01 after:border-transparent after:border after:rounded-full after:h-5 after:w-5 after:transition-all";

  const checkedToggleClasses = "peer-checked:after:translate-x-full peer-checked:after:border-neutral-light-l01 peer-checked:bg-neutral-dark";
  
  return (
    <div className={`py-xs ${className ? className : ""}`}>
      <label className="relative inline-flex items-center cursor-pointer">
        <input 
          type="checkbox" 
          id={id}
          name={name}
          className="sr-only peer" 
          checked={checked}
          disabled={disabled} 
          onChange={onChange}
        />
        <div className={`${defaultToggleClasses} ${checked ? checkedToggleClasses : ""}`}></div>
        {label &&
          <span className="text-sm text-neutral-dark-l01 px-sm">{label}</span>
        }
      </label>
    </div>
  )
}

export default Toggle;