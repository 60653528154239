import React, { useState, useEffect } from "react";
import Input from "../BasicElements/Input/Input";
import Button from "../BasicElements/Button/Button";
import Loader from "../Loader/Loader";

import { useGetUser } from "../../hooks/index";
import { useUpdateWebsiteDomain } from "./settings.hook";

const WebsiteDomain = () => {
  // TODO: replace useGetUser with useGetWebsite and select the current website
  const { loading, currentWebsite } = useGetUser();

  const { updateWebsiteDomain } = useUpdateWebsiteDomain();

  const [isUpdating, setIsUpdating] = useState(false);
  const [domain, setDomain] = useState("");

  useEffect(() => {
    if (currentWebsite && currentWebsite.domain) {
      setDomain(currentWebsite.domain);
    }

    return () => {
      setDomain("");
    };
  }, [currentWebsite]);

  const onSubmit = async e => {
    e.preventDefault();
    setIsUpdating(true);
    await updateWebsiteDomain(domain);
    setIsUpdating(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <form className="py-lg max-w-xl" onSubmit={onSubmit}>
      <Input
        label="Website URL"
        type="url"
        placeholder="e.g.https://www.alfredweb.com"
        value={domain}
        onChange={e => setDomain(e.target.value)}
      />
      <Button>Save</Button>
    </form>
  );
};

export default WebsiteDomain;
