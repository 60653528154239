import React from "react";
import { NavLink } from "react-router-dom";

function Logo() {
  return (
    <NavLink to="/overview">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M29.9998 0H0V30H29.9998V0ZM24 15C18 15 15 18.0002 15 24L6.0001 24.0002V15.0002C12.0001 15.0002 15 12.0001 15 6.0001H24V15Z" fill="#FF5925"/>
      </svg>
    </NavLink>
  )
};

export default Logo;