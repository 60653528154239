import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateNote } from "./notes.hook";

import Input from "../BasicElements/Input/Input";
import Textarea from "../BasicElements/Input/Textarea";
import Button from "../BasicElements/Button/Button";

const AddNote = () => {
  const navigate = useNavigate();
  const [note, setNote] = useState("");
  const [title, setTitle] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const { createNote } = useCreateNote();

  const addNote = async () => {
    setIsAdding(true);
    await createNote({
      body: note || "",
      title: title || ""
    });
    setIsAdding(false);
    setNote("");
    setTitle("");
    navigate("/knowledge/notes");
  };

  return (
    <section className="w-full bg-neutral-light-l01 rounded p-4xl mb-2xl">
      <h3 className='font-medium text-2xl max-w-2xl'>New note</h3>
      <form className='pt-xl md:pb-36 max-w-4xl'>
        {/* Title */}
        <Input 
          label="Title" 
          type="text" 
          placeholder="e.g. About our process" 
          value={title}
          onChange={e => setTitle(e.target.value)} 
        />
        {/* Note */}
        <Textarea 
          label="Text" 
          placeholder="Note text…" 
          helpText="Recommended size is less than 1250 characters per note." 
          maxLength={1250} 
          className="min-h-[240px]" 
          value={note}
          onChange={e => setNote(e.target.value)}
        />
        {/* Action buttons */}
        <div className="flex pt-2">
          <Button 
            onClick={addNote}
            disabled={isAdding}
            className="mr-3xl"
          >
            {isAdding ? "Creating..." : "Create"}
          </Button>
          <Button 
            type="tertiary" 
            size="sm" 
            onClick={() => navigate("/knowledge/notes")}
          >
            Back to all
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddNote;
