import React from "react";

function Dot({
  width = 20,
  height = 20,
  fill = "currentcolor",
  className
}) {
  return (
    <svg 
      className={className} 
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`} 
      fill={fill}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12Z" />
    </svg>
  );
}

export default Dot;