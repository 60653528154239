import React from "react";
import classes from "../sidebarClasses";
import { useGetUser } from "../../../hooks/index";

function WebsiteOptions({ sidebarState }) {
  let labelClasses = sidebarState ? classes.navItemLabel : "hidden";

  const { loading, currentWebsite } = useGetUser();

  let domain = currentWebsite ? currentWebsite.domain : "";
  //remove https:// or http://
  domain = domain?.replace(/(^\w+:|^)\/\//, "");

  return (
    <div className={classes.websiteOptions}>
      {/* Icon */}
      <div className={classes.navItemIcon}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.53703 9.25H6.0128C6.08326 7.19424 6.44133 5.32004 7.0041 3.89148C7.13584 3.55704 7.28139 3.24042 7.44097 2.94791C4.78379 3.91234 2.82637 6.33582 2.53703 9.25ZM10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1ZM10 2.5C9.82831 2.5 9.58572 2.5853 9.28378 2.90949C8.98082 3.23477 8.67346 3.74635 8.39971 4.44126C7.91658 5.66766 7.58375 7.34835 7.51373 9.25H12.4863C12.4163 7.34835 12.0834 5.66766 11.6003 4.44126C11.3265 3.74635 11.0192 3.23477 10.7162 2.90949C10.4143 2.5853 10.1717 2.5 10 2.5ZM13.9872 9.25C13.9167 7.19424 13.5587 5.32004 12.9959 3.89148C12.8642 3.55704 12.7186 3.24042 12.559 2.94791C15.2162 3.91234 17.1736 6.33582 17.463 9.25H13.9872ZM12.4863 10.75H7.51373C7.58375 12.6516 7.91658 14.3323 8.39971 15.5587C8.67346 16.2537 8.98082 16.7652 9.28378 17.0905C9.58572 17.4147 9.82831 17.5 10 17.5C10.1717 17.5 10.4143 17.4147 10.7162 17.0905C11.0192 16.7652 11.3265 16.2537 11.6003 15.5587C12.0834 14.3323 12.4163 12.6516 12.4863 10.75ZM12.559 17.0521C12.7186 16.7596 12.8642 16.443 12.9959 16.1085C13.5587 14.68 13.9167 12.8058 13.9872 10.75H17.463C17.1736 13.6642 15.2162 16.0877 12.559 17.0521ZM7.44097 17.0521C7.28139 16.7596 7.13584 16.443 7.0041 16.1085C6.44133 14.68 6.08326 12.8058 6.0128 10.75H2.53703C2.82637 13.6642 4.78379 16.0877 7.44097 17.0521Z"
            fill="#222426"
          />
        </svg>
      </div>
      {/* Label */}
      <span className={labelClasses}>{domain || "mywebsite.com"}</span>
    </div>
  );
}

export default WebsiteOptions;
