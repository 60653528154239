import { useQuery, useMutation } from "@apollo/client";
import {
  ContactByIdQuery,
  ContactsQuery,
  deleteContactMutation
} from "../../graphql/contacts";
import {
  AnswerSubscriptions,
  AnswerSubscription
} from "../../graphql/subscriptions";

import { useGetCurrentWebsiteId } from "../../hooks/index";

export const useGetContacts = () => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data, refetch } = useQuery(ContactsQuery, {
    variables: { webId },
    skip: !webId,
    fetchPolicy: "network-only"
  });

  const { contacts } = data || {};

  return { contacts, refetch, loading };
};

export const useGetContactById = id => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data, refetch } = useQuery(ContactByIdQuery, {
    variables: { webId, id },
    skip: !webId || !id,
    fetchPolicy: "network-only"
  });

  const { contact } = data || {};

  return { contact, refetch, loading };
};

export const useDeleteContact = () => {
  const webId = useGetCurrentWebsiteId();

  const [deleteContact, { loading, error, data }] = useMutation(
    deleteContactMutation,
    {
      variables: { webId },
      skip: !webId,
      refetchQueries: [{ query: ContactsQuery, variables: { webId } }]
    }
  );

  return {
    deleteContact: id => deleteContact({ variables: { id } }),
    loading,
    error,
    data
  };
};

export const useGetAnswerSubscriptions = () => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data, refetch } = useQuery(AnswerSubscriptions, {
    variables: { webId },
    skip: !webId,
    fetchPolicy: "network-only"
  });

  const { answerSubscriptions } = data || {};

  return { answerSubscriptions, refetch, loading };
};

export const useGetAnswerSubscription = id => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data, refetch } = useQuery(AnswerSubscription, {
    variables: { webId, id },
    skip: !webId || !id,
    fetchPolicy: "network-only"
  });

  const { answerSubscription } = data || {};

  return { answerSubscription, refetch, loading };
};
