import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";

const firebaseConfig = JSON.parse(process.env.FIREBASE_CONFIG);

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore();

if (
  window.location.host == "localhost:5000" ||
  window.location.host == "127.0.0.1:5000"
) {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
}
export default app;
