import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";
import { setProfile, resetProfile } from "../redux/user-profile.slice";
import profileQuery from "../graphql/profile";

const useGetAuthUser = () => {
  const [AuthUser, setAuthUser] = useState({
    authUser: null,
    loading: true
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), user => {
      if (!user) {
        setAuthUser({ authUser: null, loading: false });
        return;
      }
      setAuthUser({ authUser: user, loading: false });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return { ...AuthUser };
};

const useGetUserProfile = skip => {
  const { loading, error, data, called, refetch, startPolling, stopPolling } =
    useQuery(profileQuery, {
      fetchPolicy: "network-only",
      skip: skip
    });

  if (!loading) {
    if (!data && !error) {
      startPolling(1000);
    } else {
      stopPolling();
    }
  }

  const { organization, user } = data || {};
  const { websites } = organization || {};

  return {
    loading,
    error,
    data,
    refetch,
    organization,
    websites,
    user
  };
};

const useUserAuthorization = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { authUser, loading: authLoading } = useGetAuthUser();

  const {
    loading,
    error,
    data,
    refetch,
    organization: org,
    websites,
    user
  } = useGetUserProfile(!authUser || authLoading);

  useEffect(() => {
    console.log("useUserAuthorization useEffect", { authUser, data, error });
    if (loading || authLoading) return;
    if (!authUser) {
      dispatch(resetProfile()); // remove the old user profile from redux
      if (["/login", "/logout", "/backdoor"].indexOf(pathname) === -1) {
        navigate("/login");
      }

      return;
    }

    if (error && authUser) {
      refetch();
      dispatch(resetProfile()); // remove the old user profile from redux
      if (["/login", "/logout", "/backdoor"].indexOf(pathname) === -1) {
        navigate("/login");
      }
      return;
    }

    if (!data && authUser) {
      refetch();
      return;
    }

    if (data) {
      dispatch(
        setProfile({
          user,
          org,
          websites,
          currentWebsite: websites?.length && websites[0]
        })
      );
    }
  }, [loading, authLoading, authUser, dispatch, data, error]);

  return;
};

export default useUserAuthorization;
