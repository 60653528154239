import React, { useState } from "react";
import moment from "moment";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Loader from "../Loader/Loader";

import { useGetAnswerSubscriptions } from "./contacts.hook";

const AnswerSubscriptions = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { loading, answerSubscriptions, error } = useGetAnswerSubscriptions();

  if (loading) return <Loader />;

  return (
    <section>
      {/* Meta info */}
      <p className="text-sm text-neutral-dark-l02"><span className="font-bold text-neutral-dark">{answerSubscriptions?.length}</span> {answerSubscriptions?.length > 1 ? "contacts" : "contact"} subscribed to get answer to their question.</p>
      <ul className="py-4xl">
        {answerSubscriptions?.map(subscription => (
          <li 
            key={subscription.id} 
            className="mb-xs"
          >
            <Link 
              to={`${pathname}/${subscription.id}`}
              className="block bg-neutral-light-l01 rounded p-xl transition-colors duration-300 hover:bg-neutral-light-l01/60"
            >
              <span className="text-neutral-dark-l03 text-sm block mb-3xs">
                {new moment(subscription.updatedAt.seconds * 1000).format("MMM DD, YYYY")}
              </span>
              <h5 className="font-medium">{subscription.query}</h5>
              <span className="text-sm text-neutral-dark-l02">by {subscription.email}</span>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default AnswerSubscriptions;
