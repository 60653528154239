import React, { useState } from "react";
import moment from "moment";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Button from "../BasicElements/Button/Button";
import Toggle from "../BasicElements/Toggle/Toggle";
import ArrowHead from "../Icons/ArrowHead";
import { useUpdateQuestion } from "./questions.hook";

function QuestionsTableRow({
  tdClasses,
  question = {},
  highlightDisabled,
  onTitleClick
}) {
  const { updateQuestion } = useUpdateQuestion();
  const [isUpdating, setIsUpdating] = useState(false);

  const noteTitle = question.question;
  const noteBody = question.answer;
  const author = question.author;
  const updatedAt = question.updatedAt;
  const highlightChecked = question.isFeatured;

  const updateHandler = async () => {
    setIsUpdating(true);
    await updateQuestion({
      ...question,
      isFeatured: !highlightChecked
    });
    setIsUpdating(false);
  };

  const charLimit = 140;

  const date = moment(updatedAt?.seconds * 1000);

  return (
    <tr className="bg-neutral-light/0 transition-colors duration-300 hover:bg-neutral-light/60">
      {/* Question */}
      <td
        className={`${tdClasses} p-4 font-normal text-sm pl-4xl w-3/5 border-b border-neutral-light-d01`}
      >
        <div className="flex gap-2 py-md">
          <div className="cursor-pointer" onClick={onTitleClick}>
            <h6 className="font-medium text-base">{noteTitle}</h6>
            <div>
              <p className="max-w-lg py-esm text-neutral-dark-l02">
                {charLimit < noteBody.length
                  ? noteBody.slice(0, charLimit) + "…"
                  : noteBody}
              </p>
            </div>
          </div>
        </div>
      </td>
      {/* Highlight */}
      <td
        className={`${tdClasses} p-4 font-normal text-sm border-b border-neutral-light-d01`}
      >
        {isUpdating ? (
          <span className="text-sm block w-[44px]">Updating&hellip;</span>
        ) : (
          <Toggle
            onChange={updateHandler}
            checked={highlightChecked}
            disabled={highlightDisabled}
          />
        )}
      </td>
      {/* Author */}
      <td
        className={`${tdClasses} p-4 font-normal text-sm border-b border-neutral-light-d01`}
      >
        <div className="flex flex-col">
          <span>{author || "Unknown"}</span>
        </div>
      </td>
      {/* Updated */}
      <td
        className={`${tdClasses} p-4 font-normal text-sm border-b border-neutral-light-d01`}
      >
        <div className="w-max">
          <span>{date.format("MMM DD, YYYY")}</span>
        </div>
      </td>
    </tr>
  );
}

export default QuestionsTableRow;
