import React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "../../pages/login";
import Logout from "../../pages/signout";
import Backdoor from "../../pages/backdoor";
import useUserAuthorization from "../../hooks/user-authorization.hook";

import Dashboard from "./DashboardLayout";
// import "./App.css";

function App() {
  useUserAuthorization(); // authenticate user, set profile in redux store or redirect to login page
  return (
    <Routes>
      {/* full screen view */}
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/backdoor" element={<Backdoor />} />

      {/* dashboard view */}
      <Route path="/*" element={<Dashboard />} />
    </Routes>
  );
}

export default App;
