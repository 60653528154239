import React from "react";
import { NavLink, Route, Routes } from "react-router-dom";

import Queries from "../components/Queries/NoAnswerQueries";
import QueriesItem from "../components/Queries/NoAnswerQueriesItem";

const NoAnswerQueriesPage = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Queries />} />
        <Route path="/:id" element={<QueriesItem />} />
      </Routes>
    </>
  );
};

export default NoAnswerQueriesPage;

