import { gql } from "@apollo/client";

export const KnowledgeBase = gql`
  query KnowledgeBase($webId: ID!) {
    notes(webId: $webId) {
      id
      title
      body
      updatedAt {
        seconds
        nanoseconds
      }
      createdAt {
        seconds
        nanoseconds
      }
    }
  }
`;

export const KnowledgeBaseById = gql`
  query KnowledgeBaseById($webId: ID!, $id: ID!) {
    note(webId: $webId, id: $id) {
      id
      title
      body
      updatedAt {
        seconds
        nanoseconds
      }
      createdAt {
        seconds
        nanoseconds
      }
    }
  }
`;

export const CreateKnowledgeBase = gql`
  mutation CreateKnowledgeBase($webId: ID!, $note: KnowledgeBaseInput!) {
    createKnowledgeBase(webId: $webId, note: $note) {
      body
      createdAt {
        nanoseconds
        seconds
      }
      id
      title
      updatedAt {
        nanoseconds
        seconds
      }
    }
  }
`;

export const UpdateKnowledgeBase = gql`
  mutation UpdateKnowledgeBase(
    $webId: ID!
    $id: ID!
    $note: KnowledgeBaseInput!
  ) {
    updateKnowledgeBase(webId: $webId, id: $id, note: $note) {
      body
      id
      title
      updatedAt {
        nanoseconds
        seconds
      }
    }
  }
`;

export const DeleteKnowledgeBase = gql`
  mutation DeleteKnowledgeBase($webId: ID!, $id: ID!) {
    deleteKnowledgeBase(webId: $webId, id: $id) {
      body
      createdAt {
        nanoseconds
        seconds
      }
      id
      title
      updatedAt {
        nanoseconds
        seconds
      }
    }
  }
`;

export default KnowledgeBase;
