import React from "react";

function AccordionHeader({btnClassToggle, handleBtnToggle, title = 'Accordion title'}) {
  // Toggling arrow button
  let btnClass = "p-xs rounded-full bg-transparent transition-all duration-500 hover:bg-neutral-light-d01"; {/* Set up for hover state of the icon buttons */}
  btnClass = btnClassToggle ? btnClass.concat(" ", "rotate-180") : btnClass;

  return (
    <header className='flex items-center justify-between w-full'>
      <h3 className='text-2xl'>{title}</h3>
      <button onClick={handleBtnToggle} className={btnClass}> 
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M4.23214 7.20748C4.53177 6.92148 5.00651 6.93252 5.29252 7.23214L10 12.1638L14.7075 7.23215C14.9935 6.93252 15.4682 6.92148 15.7679 7.20749C16.0675 7.49349 16.0785 7.96824 15.7925 8.26786L10.5425 13.7679C10.401 13.9161 10.205 14 10 14C9.79504 14 9.59901 13.9161 9.45748 13.7679L4.20748 8.26786C3.92148 7.96823 3.93252 7.49349 4.23214 7.20748Z" fill="#222426"/>
        </svg>
      </button>
    </header>
  )
}

export default AccordionHeader;