import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import OverviewIcon from "../../Icons/Overview";
import QueriesIcon from "../../Icons/Queries";
import KnowledgeIcon from "../../Icons/Knowledge";
import ContactsIcon from "../../Icons/Contacts";
import WidgetIcon from "../../Icons/Widget";
import WebpageIcon from "../../Icons/Webpage";
import InsightsIcon from "../../Icons/Insights";
import ArrowHeadIcon from "../../Icons/ArrowHead";
import DotIcon from "../../Icons/Dot";
import classes from "../sidebarClasses";

const NavItemNested = ({ children, label, icon, labelClasses }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = e => {
    setIsOpen(!isOpen);
  };

  let navItemParentClasses;

  if (isOpen) {
    navItemParentClasses = `${classes.navItem} ${classes.navItemMain} ${classes.navItemParent} ${classes.navItemParentOpen}`;
  } else {
    navItemParentClasses = `${classes.navItem} ${classes.navItemMain} ${classes.navItemParent}`;
  }

  return (
    <>
      <button onClick={handleClick} className={navItemParentClasses}>
        <div className="flex items-center">
          <div className={classes.navItemIcon}>{icon}</div>
          <span className={labelClasses}>{label}</span>
        </div>
        <ArrowHeadIcon className={isOpen ? "rotate-180" : ""} />
      </button>
      {isOpen && children}
    </>
  );
};

function MainNav({ sidebarState }) {
  const navItemMainClasses = `${classes.navItem} ${classes.navItemHover} ${classes.navItemMain}`;
  let navItemComingClasses, labelClasses;

  if (sidebarState) {
    navItemComingClasses = `${classes.navItem} ${classes.navItemMain} ${classes.navItemDisabled} ${classes.navItemComing}`;
    labelClasses = classes.navItemLabel;
  } else {
    navItemComingClasses = `${classes.navItem} ${classes.navItemMain} ${classes.navItemDisabled} ${classes.navItemComing} after:hidden`;
    labelClasses = "hidden";
  }

  return (
    <nav>
      <ul>
        <li>
          <NavLink
            to="/overview"
            className={({ isActive }) =>
              isActive
                ? `${classes.navItemActive} ${navItemMainClasses}`
                : navItemMainClasses
            }
          >
            <div className={classes.navItemIcon}>
              <OverviewIcon />
            </div>
            <span className={labelClasses}>Overview</span>
          </NavLink>
        </li>
        <li>
          <NavItemNested
            icon={<QueriesIcon />}
            label="Queries"
            labelClasses={labelClasses}
          >
            <ul className={classes.navItemChildren}>
              <li>
                <NavLink
                  to="/queries/cache"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navItemActive} ${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                      : `${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                  }
                >
                  <div className={classes.navItemIcon}>
                    <DotIcon />
                  </div>
                  <span className={labelClasses}>
                    Cache
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/queries/no-answer"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navItemActive} ${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                      : `${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                  }
                >
                  <div className={classes.navItemIcon}>
                    <DotIcon />
                  </div>
                  <span className={labelClasses}>Without answer</span>
                </NavLink>
              </li>
            </ul>
          </NavItemNested>
        </li>
        <li>
          <NavItemNested
            icon={<KnowledgeIcon />}
            label="Knowledge"
            labelClasses={labelClasses}
          >
            <ul className={classes.navItemChildren}>
              <li>
                <NavLink
                  to="/knowledge/notes"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navItemActive} ${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                      : `${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                  }
                >
                  <div className={classes.navItemIcon}>
                    <DotIcon />
                  </div>
                  <span className={labelClasses}>Notes</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/knowledge/questions"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navItemActive} ${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                      : `${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                  }
                >
                  <div className={classes.navItemIcon}>
                    <DotIcon />
                  </div>
                  <span className={labelClasses}>Questions</span>
                </NavLink>
              </li>
            </ul>
          </NavItemNested>
        </li>
        <li>
          <NavItemNested
            icon={<ContactsIcon />}
            label="Contacts"
            labelClasses={labelClasses}
          >
            <ul className={classes.navItemChildren}>
              <li>
                <NavLink
                  to="/contacts/all"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navItemActive} ${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                      : `${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                  }
                >
                  <div className={classes.navItemIcon}>
                    <DotIcon />
                  </div>
                  <span className={labelClasses}>
                    All
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contacts/subscribers"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navItemActive} ${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                      : `${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                  }
                >
                  <div className={classes.navItemIcon}>
                    <DotIcon />
                  </div>
                  <span className={labelClasses}>
                    Subscribers
                  </span>
                </NavLink>
              </li>
            </ul>
          </NavItemNested>
        </li>
        <li>
          <NavItemNested
            icon={<WidgetIcon />}
            label="Widget"
            labelClasses={labelClasses}
          >
            <ul className={classes.navItemChildren}>
              <li>
                <NavLink
                  to="/widget/installation"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navItemActive} ${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                      : `${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                  }
                >
                  <div className={classes.navItemIcon}>
                    <DotIcon />
                  </div>
                  <span className={labelClasses}>Installation</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/widget/content"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navItemActive} ${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                      : `${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                  }
                >
                  <div className={classes.navItemIcon}>
                    <DotIcon />
                  </div>
                  <span className={labelClasses}>Content</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/widget/design"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navItemActive} ${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                      : `${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                  }
                >
                  <div className={classes.navItemIcon}>
                    <DotIcon />
                  </div>
                  <span className={labelClasses}>Design</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/widget/demo"
                  className={({ isActive }) =>
                    isActive
                      ? `${classes.navItemActive} ${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                      : `${classes.navItem} ${classes.navItemHover} ${classes.navItemChild}`
                  }
                >
                  <div className={classes.navItemIcon}>
                    <DotIcon />
                  </div>
                  <span className={labelClasses}>Demo</span>
                </NavLink>
              </li>
            </ul>
          </NavItemNested>
        </li>
        <li>
          <NavLink to="/webpage" className={navItemComingClasses}>
            <div className={classes.navItemIcon}>
              <WebpageIcon />
            </div>
            <span className={labelClasses}>Webpage</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/insights" className={navItemComingClasses}>
            <div className={classes.navItemIcon}>
              <InsightsIcon />
            </div>
            <span className={labelClasses}>Insights</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default MainNav;
