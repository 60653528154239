import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../Loader/Loader";
import Button from "../BasicElements/Button/Button";
import ArrowBack from "../Icons/ArrowDown";
import { useGetQueryById, useDeleteQuery } from "./queries.hook";
import { useAddQuestion } from "../Questions/questions.hook";

const AnswerLessQuery = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { query, loading } = useGetQueryById(id);

  const { deleteQuery } = useDeleteQuery();
  const { addQuestion } = useAddQuestion();

  const [isAction, setIsAction] = useState(false);

  const handleDelete = async () => {
    setIsAction(true);
    await deleteQuery(id);
    setIsAction(false);
    navigate("/queries/cache");
  };

  const copyToQuestions = async () => {
    setIsAction(true);
    await deleteQuery(id);
    const resp = await addQuestion({
      question: query.query,
      answer: query.answer,
      isFeatured: false
    });
    const { addQuestion: question } = resp?.data || {};
    const { id: questionId } = question || {};
    setIsAction(false);
    navigate("/knowledge/questions/" + questionId);
  };

  if (loading) return <Loader />;
  if (isAction) return <Loader />;
  if (!query) return <div>No query found</div>;
  return (
    <section className="bg-neutral-light-l01 rounded p-4xl mb-2xl">
      {/* Back to all items */}
      <header>
        <Button
          type="tertiary"
          size="sm"
          onClick={() => {
            navigate("/queries/cache");
          }}
        >
          <ArrowBack className="rotate-90 mr-3xs" />
          Back to all cache queries
        </Button>
      </header>
      {/* Main content */}
      <div className="py-3xl">
        <span className="text-neutral-dark-l03 text-sm block mb-xs">
          {new moment(query.createdAt.seconds * 1000).format("MMM DD, YYYY")}
        </span>
        <h3 className="font-medium text-2xl max-w-2xl mb-e4xs">
          {query.query}
        </h3>
        <p className="text-neutral-dark-l02 max-w-2xl">{query.answer}</p>
      </div>
      {/* Action buttons */}
      <footer className="md:flex">
        <Button onClick={copyToQuestions} className="mr-3xl">
          Copy to questions
        </Button>
        <Button type="tertiary" size="sm" onClick={handleDelete}>
          Delete
        </Button>
      </footer>
    </section>
  );
};

export default AnswerLessQuery;
