import React, { useState, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Loader from "../Loader/Loader";
import TableSearch from "../Notes/TableSearch";
import QuestionsTableRow from "./QuestionsTableRow";
import TableHead from "../Notes/TableHead";
import Button from "../BasicElements/Button/Button";
import { questionsTabData } from "../../pages/data/knowledgeData";
import { useGetQuestions } from "./questions.hook";

function QuestionsTable() {
  const { loading, questions } = useGetQuestions();
  const Navigate = useNavigate();

  // Search functionality
  const [searchInput, setSearchInput] = useState("");
  const handleInputChange = e => {
    setSearchInput(e.target.value);
  };

  const sorted = useMemo(() => {
    if (questions?.length) {
      return Array.from(questions)?.sort((a, b) => {
        return b.question < a.question;
      });
    }

    return questions;
  }, [questions]);

  const searchResult = sorted?.filter(row => {
    const question = row.question.toLowerCase();
    const answer = row.answer.toLowerCase();
    return (
      question.includes(searchInput.toLowerCase()) ||
      answer.includes(searchInput.toLowerCase())
    );
  });

  const tableRows = searchInput ? searchResult : sorted;
  const rowLimit = 5;
  const rowPerView = 10;
  const showLoadMore =
    tableRows?.length > rowLimit && tableRows?.length > rowPerView;

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {/* Table */}
      <div className="w-full bg-neutral-light-l01 rounded-md pb-md mb-lg">
        {/* Header */}
        <TableSearch
          searchPlaceholder={questionsTabData.searchPlaceholder}
          onChange={handleInputChange}
          value={searchInput}
        />
        {/* Body */}
        <div className="overflow-scroll px-0">
          {tableRows.length > 0 ? (
            <table className="w-full min-w-max table-auto text-left">
              <TableHead tableHeadTitles={questionsTabData.tableHead} />
              <tbody>
                {tableRows.map(
                  (
                    question /*{ id, question, answer, author, updatedAt, isFeatured }*/,
                    index
                  ) => {
                    const isLast = index === tableRows.length - 1;
                    const tdClasses = isLast ? "border-transparent" : "";

                    return (
                      <QuestionsTableRow
                        tdClasses={tdClasses}
                        key={index}
                        question={question}
                        highlightDisabled={false}
                        onTitleClick={() => {
                          Navigate(`./${question.id}`, {
                            relative: "path"
                          });
                        }}
                      />
                    );
                  }
                )}
              </tbody>
            </table>
          ) : (
            <p className="text-center p-4 text-neutral-dark-l02">
              No matches found for{" "}
              <span className="text-neutral-dark font-medium">
                "{searchInput}"
              </span>
            </p>
          )}
        </div>
        {/* Footer */}
        {showLoadMore ? (
          <footer className="flex items-center justify-center p-4">
            <Button type="tertiary" size="sm">
              Load more
            </Button>
          </footer>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default QuestionsTable;
