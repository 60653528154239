import React from "react";

function Button({
  children,
  type = "primary",
  size = "base",
  className,
  handleClick,
  onClick,
  disabled
}) {
  const types = {
    primary: {
      default:
        "inline-flex items-center bg-neutral-dark border border-neutral-dark text-neutral-light font-bold px-exl py-exs rounded transition-all duration-500 whitespace-nowrap",
      hover: "hover:bg-transparent hover:text-neutral-dark",
      active: "active:bg-transparent",
      focus: "focus:outline-none focus:ring focus:ring-accent-secondary-l03"
    },
    secondary: {
      default:
        "inline-flex items-center bg-transparent border border-neutral-dark text-neutral-dark  font-semibold px-exl py-exs rounded transition-all duration-500 whitespace-nowrap",
      hover: "hover:bg-neutral-light-d01 hover:border-neutral-light-d01",
      active: "active:bg-neutral-light-d01 active:border-neutral-light-d01",
      focus: "focus:outline-none focus:ring focus:ring-accent-secondary-l03"
    },
    tertiary: {
      default:
        "inline-flex items-center bg-transparent text-neutral-dark-l02 font-medium py-exs rounded transition-all duration-500 whitespace-nowrap",
      hover: "hover:text-accent-secondary-d01",
      active: "active:text-neutral-dark",
      focus: "focus:outline-none focus:ring focus:ring-accent-secondary-l03"
    },
    quaternary: {
      default:
        "inline-flex items-center bg-neutral-light-d01 border border-neutral-light-d01 text-neutral-dark font-bold px-exl py-exs rounded transition-all duration-500 whitespace-nowrap",
      hover: "hover:bg-transparent hover:text-neutral-dark hover:border-neutral-dark",
      active: "active:bg-transparent",
      focus: "focus:outline-none focus:ring focus:ring-accent-secondary-l03"
    },
    circular: {
      default:
        "bg-neutral-dark flex items-center justify-center text-neutral-light border border-neutral-dark font-medium p-e3xs rounded-full transition-all duration-500",
      hover: "hover:bg-transparent hover:text-neutral-dark",
      active: "active:bg-transparent",
      focus: "focus:outline-none focus:ring focus:ring-accent-secondary-l03"
    }
  };
  const sizes = ["xs", "sm", "base", "lg", "xl"];
  const sizeModifier = sizes.find(el => el === size);
  let classes = "";

  if (type === "primary") {
    classes = `${types.primary.default} ${types.primary.hover} ${types.primary.active} ${types.primary.focus}`;
  } else if (type === "secondary") {
    classes = `${types.secondary.default} ${types.secondary.hover} ${types.secondary.active} ${types.secondary.focus}`;
  } else if (type === "tertiary") {
    classes = `${types.tertiary.default} ${types.tertiary.hover} ${types.tertiary.active} ${types.tertiary.focus}`;
  } else if (type === "quaternary") {
    classes = `${types.quaternary.default} ${types.quaternary.hover} ${types.quaternary.active} ${types.quaternary.focus}`;
  } else if (type === "circular") {
    classes = `${types.circular.default} ${types.circular.hover} ${types.circular.active} ${types.circular.focus}`;
  }

  classes = `${classes} text-${sizeModifier}`;
  classes = className ? `${classes} ${className}` : classes;

  return (
    <button
      className={classes}
      onClick={onClick || handleClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default Button;
