import React from "react";

function Input({
  label,
  type,
  name,
  placeholder,
  id,
  required,
  className,
  children,
  fileTypes,
  value = "",
  valid,
  onChange,
  customRef,
  defaultValue,
  readOnly
}) {
  const classes = {
    container: "flex flex-col w-full",
    fileContainer:
      "flex bg-neutral-light-d01 p-md mr-sm mb-md block rounded cursor-pointer transition-colors duration-300 hover:bg-neutral-light-d02",
    label: "text-sm text-neutral-dark-l01 mb-esm",
    fileLabel: "text-sm text-neutral-dark font-medium",
    inputDefault:
      "bg-transparent text-md p-md mr-sm mb-md block border rounded border-neutral-light-d02",
    inputFocus:
      "focus:outline-none focus:border-neutral-light-d03 focus:ring-2 focus:ring-neutral-light-d02 focus:border-neutral-light-d02",
    inputDisabled:
      "disabled:bg-neutral-light disabled:text-neutral-light-d03 disabled:placeholder:text-neutral-light-d03 disabled:border-neutral-light-d01",
    inputInvalid:
      "invalid:border-accent-primary-l01 invalid:text-accent-primary-d03",
    inputFocusInvalid:
      "focus:invalid:border-accent-primary-l01 focus:invalid:ring-accent-primary-l03",
    urlInputInvalid: "border-accent-primary-l01 text-accent-primary-d03",
    urlInputFocusInvalid: "focus:border-accent-primary-l01 focus:ring-accent-primary-l03"
  };

  let URLInputClasses = `${classes.inputDefault} ${classes.inputFocus} ${
    classes.inputDisabled
  } pl-5xl pr-md grow ${className ? className : ""}`;

  if (value != "") {
    URLInputClasses = valid ? URLInputClasses : `${URLInputClasses} ${classes.urlInputInvalid} ${classes.urlInputFocusInvalid}`;
  }
  
  const fileInput = (
    <label className={classes.fileContainer}>
      <div className="mr-2xs">{children}</div>
      {value && (
        <span className={`${classes.fileLabel} font-normal`}>
          File <span className="font-bold">{value.name}</span> &mdash;{" "}
          {value.size}
        </span>
      )}
      {!value && <span className={classes.fileLabel}>{label}</span>}
      <input
        className="w-0 h-0"
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        id={id}
        required={required}
        name={name}
        ref={customRef}
      />
    </label>
  );

  const defaultInput = (
    <label className={classes.container}>
      {label && <span className={classes.label}>{label}</span>}
      {children && (
        <div className="relative flex">
          {children && (
            <div className="absolute left-4 top-1/2 translate-y-[calc(-50%-0.5rem)]">
              {children}
            </div>
          )}
          <input
            className={`${classes.inputDefault} ${classes.inputFocus} ${
              classes.inputDisabled
            } ${classes.inputInvalid} ${
              classes.inputFocusInvalid
            } pl-5xl pr-md grow ${className ? className : ""}`}
            onChange={onChange}
            type={type}
            placeholder={placeholder}
            value={value}
            id={id}
            required={required}
            name={name}
            ref={customRef}
            defaultValue={defaultValue}
            readOnly={readOnly}
          />
        </div>
      )}
      {!children && (
        <input
          className={`${classes.inputDefault} ${classes.inputFocus} ${
            classes.inputDisabled
          } ${classes.inputInvalid} ${classes.inputFocusInvalid} ${
            className ? className : ""
          }`}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          value={value}
          id={id}
          required={required}
          name={name}
          ref={customRef}
          defaultValue={defaultValue}
        />
      )}
    </label>
  );

  const URLInput = (
    <label className={classes.container}>
      {label && <span className={classes.label}>{label}</span>}
      {children && (
        <div className="relative flex">
          {children && (
            <div className="absolute left-4 top-1/2 translate-y-[calc(-50%-0.5rem)]">
              {children}
            </div>
          )}
          <input
            className={URLInputClasses}
            style={{ paddingLeft: "6rem" }}
            onChange={onChange}
            // type={type}
            placeholder={placeholder}
            value={value}
            id={id}
            required={required}
            name={name}
            ref={customRef}
            defaultValue={defaultValue}
            readOnly={readOnly}
          />
        </div>
      )}
      {!children && (
        <input
          className={`${classes.inputDefault} ${classes.inputFocus} ${
            classes.inputDisabled
          } ${classes.inputInvalid} ${classes.inputFocusInvalid} ${
            className ? className : ""
          }`}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          value={value}
          id={id}
          required={required}
          name={name}
          ref={customRef}
          defaultValue={defaultValue}
        />
      )}
    </label>
  );

  // return (
  //   <>
  //     {/* File input */}
  //     {type === "file" && fileInput}
  //     {type !== "file" && defaultInput}
  //   </>
  // );
  //replace with switch statement
  switch (type) {
    case "file":
      return fileInput;
    case "url":
      return URLInput;
    default:
      return defaultInput;
  }
}

export default Input;
