import React from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const MainNav = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          px: 4,
          pt: 3,
          pb: 3,
          backgroundColor: "light.dark"
        }}
      >
        <header className="md:flex items-center justify-between py-sm">
          <h1 className="text-2xl font-bold text-dark">
            <Link to="/dashboard">Alfred</Link>
          </h1>
          <div>
            <Link to="/demo" className="text-sm mr-sm text-dark">
              Demo
            </Link>
            <Link to="/queries" className="text-sm mr-sm text-dark">
              Queries
            </Link>
            <Link to="/contacts" className="text-sm mr-sm text-dark">
              Contacts
            </Link>
            <Link to="/activities" className="text-sm mr-sm text-dark">
              Activities
            </Link>
            <Link to="/questions" className="text-sm mr-sm text-dark">
              Questions
            </Link>
            <Link to="/knowledge" className="text-sm mr-sm text-dark">
              Knowledge
            </Link>
            <Link to="/settings" className="text-sm pl-xs pr-xs text-dark">
              Settings
            </Link>

            <Link to="/logout" className="text-sm pl-xs pr-xs text-dark">
              Logout{" "}
              {/* <span className="text-dark-l02">
                portal {user?.currentPortal?.hubId}
              </span> */}
            </Link>
          </div>
        </header>
      </Paper>
    </Box>
  );
};

export default MainNav;
