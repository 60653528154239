import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Accordion from "../components/Accordion/Accordion";
import Button from "../components/BasicElements/Button/Button";
import BillingButton from "../components/Billing/BillingBtn";
import Input from "../components/BasicElements/Input/Input";
import Progress from "../components/BasicElements/Progress/Progress";

import { useGetAnswerLessQueries } from "../components/Queries/queries.hook";
import { useGetContacts } from "../components/Contacts/contacts.hook";
import { useGetNotes } from "../components/Notes/notes.hook";
import { useGetQuestions } from "../components/Questions/questions.hook";
import { useUpdateUserName } from "../components/Users/users.hook";

import {
  useSetFreePlan,
  useGetPlans
} from "../components/Billing/billing.hook";

import { useGetUser } from "../hooks/index";

const General = () => {
  const { user, loading } = useGetUser();
  const { updateUserName } = useUpdateUserName();
  const [name, setName] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (!user) return;

    setName(user?.name);
  }, [user, loading]);

  const saveHandler = async e => {
    e.preventDefault();
    setIsUpdating(true);
    updateUserName(name);
    setIsUpdating(false);
  };

  return (
    <Accordion title="General">
      <div className="pt-md">
        {/* Meta info */}
        <p className="text-sm text-neutral-dark-l01 mb-xs">
          Logged in as <span className="font-medium text-neutral-dark">{user?.email}</span>
        </p>
        {/* Form */}
        <form className="py-lg max-w-xl">
          <fieldset className="md:flex w-full">
            <Input
              label="Full Name"
              type="text"
              placeholder="e.g. Alfred Web"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </fieldset>
          {/* <Input
            label="Email"
            type="email"
            disabled
            placeholder="e.g. alfredwebb@email.com"
            value={user?.email}
          /> */}
          <Button onClick={saveHandler} disabled={isUpdating}>
            Save
          </Button>
        </form>
      </div>
    </Accordion>
  );
};

const Billing = () => {
  const { answerLessQueries } = useGetAnswerLessQueries();
  const { contacts } = useGetContacts();
  const { notes } = useGetNotes();
  const { questions } = useGetQuestions();
  const { currentWebsite = {}, org } = useGetUser();
  const currentPlan = currentWebsite?.plan;
  const storage = currentPlan?.storage;
  const { setFreePlan } = useSetFreePlan();
  const { plans } = useGetPlans();
  const planNames = plans?.map(plan => {
    return plan?.name;
  });

  let showUpgrade, nextPlanName;

  const currentPlanNameIndex = planNames?.indexOf(currentPlan?.name);
  if (currentPlanNameIndex >= 0) {
    nextPlanName = planNames[currentPlanNameIndex + 1];
    showUpgrade = currentPlanNameIndex < planNames?.length - 1;
  }

  const showPaymentMethod = currentPlan?.name == "Free" ? false : true;

  return (
    <Accordion title="Subscription">
      <div className="pt-3xl">
        {/* Subscription plan */}
        <div className="border-t border-neutral-light-d02 pt-3xl mb-3xl">
          {/* Header */}
          <header className="md:flex md:justify-between mb-2xl">
            <div className="flex grow mb-md">
              <div className="pr-md basis-1/2 lg:w-[240px] xl:basis-auto">
                <span className="text-neutral-dark-l03 text-sm">Plan</span>
                <h4 className="text-xl w-full">{currentPlan?.label}</h4>
              </div>
              <div className="pr-md basis-1/2 lg:w-[240px] xl:basis-auto">
                <span className="text-neutral-dark-l03 text-sm">Payment</span>
                <span className="block w-full">
                  <span className="text-xl">{currentPlan?.price}</span>
                  <span className="inline-block ml-e4xs text-neutral-dark-l03 text-md">
                    /mo
                  </span>
                </span>
              </div>
            </div>
            <div className="flex flex-col items-center">
              {showUpgrade ? (
                <BillingButton>Upgrade to {nextPlanName}</BillingButton>
              ) : (
                ""
              )}
              <Link
                to="https://alfredweb.com/pricing.html"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center bg-transparent text-neutral-dark-l02 font-medium py-exs rounded transition-all duration-500 whitespace-nowrap hover:text-accent-secondary-d01 active:text-neutral-dark focus:outline-none focus:ring focus:ring-accent-secondary-l03 text-sm"
              >
                See all pricing plans
              </Link>
            </div>
          </header>
          {/* Details */}
          <div>
            {/* Website */}
            <div>
              <span className="px-xs py-3xs mr-xs border border-neutral-light-d01 rounded leading-none text-neutral-dark-l03 inline-block">
                {storage?.websites}
              </span>
              Website
            </div>
            {/* Parameters */}
            <div className="py-3xl grid max-w-[540px] gap-4 grid-cols-2">
              <div>
                <h5 className="mb-2xs">Cached queries</h5>
                {/* Progress bar */}
                <Progress
                  max={storage?.queries}
                  value={answerLessQueries?.length}
                />
                <span className="text-neutral-dark-l03 text-sm">
                  {answerLessQueries?.length}/{storage?.queries}
                </span>
              </div>
              <div>
                <h5 className="mb-2xs">Contacts</h5>
                {/* Progress bar */}
                <Progress max={storage?.contacts} value={contacts?.length} />
                <span className="text-neutral-dark-l03 text-sm">
                  {contacts?.length}/{storage?.contacts}
                </span>
              </div>
              <div>
                <h5 className="mb-2xs">
                  Notes{" "}
                  <span className="text-neutral-dark-l03 text-sm block">
                    knowledge storage
                  </span>
                </h5>
                {/* Progress bar */}
                <Progress max={storage?.knowledge} value={notes?.length} />
                <span className="text-neutral-dark-l03 text-sm">
                  {notes?.length}/{storage?.knowledge}
                </span>
              </div>
              <div>
                <h5 className="mb-2xs">
                  Questions{" "}
                  <span className="text-neutral-dark-l03 text-sm block">
                    knowledge storage
                  </span>
                </h5>
                {/* Progress bar */}
                <Progress max={storage?.questions} value={questions?.length} />
                <span className="text-neutral-dark-l03 text-sm">
                  {questions?.length}/{storage?.questions}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Payment method */}
        {showPaymentMethod && (
          <div className="border-t border-neutral-light-d02 pt-3xl">
            <h4 className="text-xl mb-3xl">Payment method</h4>
            <div className="md:flex">
              {/* Card details */}
              <div className="grow flex mb-2xl md:mb-0">
                {/* Icon */}
                <div className="flex items-center justify-center px-xs py-3xs mr-xs border border-neutral-light-d01 rounded inline-block grow-0 shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentcolor"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6V9H2V6ZM0 10V6C0 4.34315 1.34315 3 3 3H21C22.6569 3 24 4.34315 24 6V10V18C24 19.6569 22.6569 21 21 21H3C1.34315 21 0 19.6569 0 18V10ZM22 11V18C22 18.5523 21.5523 19 21 19H3C2.44772 19 2 18.5523 2 18V11H22Z"
                    />
                  </svg>
                </div>
                <div>
                  <div className="flex items-center">
                    <svg
                      className="mr-xs text-neutral-dark-l04"
                      xmlns="http://www.w3.org/2000/svg"
                      width="132"
                      height="6"
                      viewBox="0 0 132 6"
                      fill="currentcolor"
                    >
                      <path d="M3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6Z" />
                      <path d="M13 6C14.6569 6 16 4.65685 16 3C16 1.34315 14.6569 0 13 0C11.3431 0 10 1.34315 10 3C10 4.65685 11.3431 6 13 6Z" />
                      <path d="M26 3C26 4.65685 24.6569 6 23 6C21.3431 6 20 4.65685 20 3C20 1.34315 21.3431 0 23 0C24.6569 0 26 1.34315 26 3Z" />
                      <path d="M33 6C34.6569 6 36 4.65685 36 3C36 1.34315 34.6569 0 33 0C31.3431 0 30 1.34315 30 3C30 4.65685 31.3431 6 33 6Z" />
                      <path d="M51 6C52.6569 6 54 4.65685 54 3C54 1.34315 52.6569 0 51 0C49.3431 0 48 1.34315 48 3C48 4.65685 49.3431 6 51 6Z" />
                      <path d="M61 6C62.6569 6 64 4.65685 64 3C64 1.34315 62.6569 0 61 0C59.3431 0 58 1.34315 58 3C58 4.65685 59.3431 6 61 6Z" />
                      <path d="M74 3C74 4.65685 72.6569 6 71 6C69.3431 6 68 4.65685 68 3C68 1.34315 69.3431 0 71 0C72.6569 0 74 1.34315 74 3Z" />
                      <path d="M81 6C82.6569 6 84 4.65685 84 3C84 1.34315 82.6569 0 81 0C79.3431 0 78 1.34315 78 3C78 4.65685 79.3431 6 81 6Z" />
                      <path d="M102 3C102 4.65685 100.657 6 99 6C97.3431 6 96 4.65685 96 3C96 1.34315 97.3431 0 99 0C100.657 0 102 1.34315 102 3Z" />
                      <path d="M112 3C112 4.65685 110.657 6 109 6C107.343 6 106 4.65685 106 3C106 1.34315 107.343 0 109 0C110.657 0 112 1.34315 112 3Z" />
                      <path d="M119 6C120.657 6 122 4.65685 122 3C122 1.34315 120.657 0 119 0C117.343 0 116 1.34315 116 3C116 4.65685 117.343 6 119 6Z" />
                      <path d="M132 3C132 4.65685 130.657 6 129 6C127.343 6 126 4.65685 126 3C126 1.34315 127.343 0 129 0C130.657 0 132 1.34315 132 3Z" />
                    </svg>
                    <span>{org?.subscription.card_last_four}</span>
                  </div>
                  {/* <span className="text-neutral-dark-l03 text-xs">
                    Expiry {userSubscriptionData.cardExpiry}
                  </span> */}
                </div>
              </div>
              {/* Cancel subscription */}
              <Button size="sm" type="tertiary" onClick={setFreePlan}>
                Cancel subscription
              </Button>
            </div>
          </div>
        )}
      </div>
    </Accordion>
  );
};

const Profile = () => {
  return (
    <section>
      {/* General profile information like name, email, etc. */}
      <General />
      {/* Subscription information like current plan, payment method */}
      <Billing />
    </section>
  );
};

export default Profile;
