import React from "react";

function ArrowHead({
  width = 20,
  height = 20,
  fill = "currentcolor",
  className
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`} 
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8944 7.55376C14.0638 7.89255 14.0273 8.29796 13.8 8.60098L10.8 12.601C10.6111 12.8528 10.3148 13.001 9.99999 13.001C9.68524 13.001 9.38885 12.8528 9.19999 12.601L6.19999 8.60098C5.97273 8.29796 5.93617 7.89255 6.10557 7.55376C6.27496 7.21498 6.62122 7.00098 6.99999 7.00098L13 7.00098C13.3788 7.00098 13.725 7.21498 13.8944 7.55376Z"
      />
    </svg>
  );
}

export default ArrowHead;
