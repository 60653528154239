import React, { useRef, useState, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import Loader from "../../components/Loader/Loader";
// import ArrowTopRightIcon from "../../assets/arrow-top-right.svg";s
import { useGetUser } from "../../hooks/index";
import { useGetLoggedUserToken } from "../../hooks/get-auth-user.hook";

const Onboard = () => {
  const navigate = useNavigate();
  const { user, org, loading } = useGetUser();
  const [token] = useGetLoggedUserToken();

  const [installing, setInstalling] = useState(false);
  const [validationError, setValidationError] = useState(false);

  const install = async e => {
    e.preventDefault();
    const url = e.target.url.value;
    const regex =
      /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

    if (!url) {
      setValidationError("Please enter an url");
      return;
    }

    if (!regex.test(url)) {
      setValidationError("Please enter a valid url");
      return;
    }

    setInstalling(true);

    try {
      const resp = await fetch(`/api/add-website/${org.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          url
        })
      });

      const data = await resp.json();

      setInstalling(false);

      navigate("/onboarding/2");
    } catch (err) {
      alert("Something went wrong. Site not added.");
      console.error(err);
      setInstalling(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="flex">
      <section
        className="px-3xl py-5xl bg-light-d01 flex flex-col justify-between"
        style={{ minHeight: "100vh" }}
      >
        <div>
          <div className="mb-2xl">
            <h2 className="text-lg font-medium leading-sm mb-md">
              ”Alfred” initial setup
            </h2>
            <h3 className="text-md font-medium leading-sm mb-md">
              1/2 Type your website URL:
            </h3>
            <p className="text-xs text-dark-l01">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            </p>
          </div>

          <form className="" onSubmit={install}>
            <div className="mb-md">
              <label className="text-xs text-dark-l01" htmlFor="url">
                Website URL
              </label>
              <input
                className="input input--sm"
                type="text"
                name="url"
                id="url"
                placeholder="https://example.com"
              />
              {validationError && (
                <p className="text-xs text-red mt-sm">{validationError}</p>
              )}
            </div>
            <button
              type="submit"
              disabled={installing}
              className="button button--sm button--secondary mb-lg"
            >
              {installing ? "Creating..." : "Submit"}
            </button>
          </form>
        </div>
        <div className="text-dark-l03 flex items-center">
          ALFRED{" "}
          <span className="text-xs">
            by{" "}
            <a
              className="underline hover:text-dark visited:text-dark-l03"
              href="https://kohorta.co/"
              target="_blank"
            >
              Kohorta
            </a>
          </span>
        </div>
      </section>
      <section className="grow"></section>
    </div>
  );
};

export default Onboard;
