import React, { useState, useRef } from "react";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";

import Loader from "../Loader/Loader";
import UpdateNote from "./UpdateNote";
import Button from "../BasicElements/Button/Button";
import ArrowBack from "../Icons/ArrowDown";

import { 
  useGetNote, 
  useDeleteNote 
} from "./notes.hook";

const NoteItem = ({ note, onEdit }) => {
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const { deleteNote } = useDeleteNote();

  const onDelete = async id => {
    setIsDeleting(true);
    await deleteNote({
      id: id
    });
    setIsDeleting(false);
    navigate("/notes");
  };

  if (!note) return <p>Note not found</p>;

  return (
    <>
      {/* Back to all items */}
      <header>
        <Button 
          type="tertiary" 
          size="sm" 
          onClick={()=>{navigate("/knowledge/notes")}}
        >
          <ArrowBack className="rotate-90 mr-3xs" />
          Back to all notes
        </Button>
      </header>
      {/* Main content */}
      <div className="py-3xl">
        {/* Meta information */}
        <div className="mb-sm">
          <span className="text-neutral-dark-l03 text-sm block mb-xs">
            Updated: {new moment(note.updatedAt?.seconds * 1000).format("MMM DD, YYYY")}
          </span>
          {note.user?.name && 
            <span className="text-neutral-dark-l03 text-sm block mb-xs">
              Author: {note.user?.name}
            </span>
          }
        </div>
        {/* Title */}
        <h3 className="font-medium text-2xl max-w-2xl mb-e4xs">{note.title}</h3>
        {/* Text */}
        <p className="text-neutral-dark-l02 max-w-2xl">{note.body}</p>
      </div>
      {/* Action buttons */}
      <footer className="md:flex">
        <Button 
          onClick={() => {
            onEdit();
          }}
          className="mr-3xl"  
          type="primary"
          size="md"
        >
          Edit
        </Button> 
        <Button 
          type="tertiary" 
          size="sm" 
          onClick={() => onDelete(note.id)}
        >
          {isDeleting ? "Deleting..." : "Delete"}
        </Button>
      </footer>
    </>
  );
};

const Note = () => {
  const { id } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const { note, loading, refetch } = useGetNote({ id });

  const noteRender = isEditing ? (
    <UpdateNote 
      noteData={note}
      onUpdateNote={() => {
        setIsEditing(false);
        refetch();
      }}
    />
  ) : (
    <NoteItem 
      note={note} 
      onEdit={() => {setIsEditing(true);}}
    />
  );

  if (loading) return <Loader />;

  return (
    <section className="bg-neutral-light-l01 rounded p-4xl mb-2xl">
      {noteRender}
    </section>
  );
}

export default Note;
