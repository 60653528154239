import { useQuery, useMutation } from "@apollo/client";
import {
  questionQueryId,
  questionsQuery,
  addQuestionQuery,
  updateQuestionQuery,
  deleteQuestionQuery,
  GenerateAnswerMutation
} from "../../graphql/questions";
import { useGetCurrentWebsiteId } from "../../hooks/index";

export const useGetQuestions = () => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data, refetch, called } = useQuery(questionsQuery, {
    variables: { webId },
    skip: !webId
  });

  const { questions } = data || {};

  return { questions, refetch, loading: loading || !called };
};

export const useGetQuestion = ({ id }) => {
  const webId = useGetCurrentWebsiteId();

  const { loading, error, data, refetch, called } = useQuery(questionQueryId, {
    variables: { webId, id },
    skip: !webId
  });

  const { question } = data || {};

  return { question, refetch, loading: loading || !called };
};

export const useAddQuestion = () => {
  const webId = useGetCurrentWebsiteId();

  const [addQuestion, { loading, error, data }] = useMutation(
    addQuestionQuery,
    {
      variables: { webId },
      skip: !webId,
      refetchQueries: [{ query: questionsQuery, variables: { webId } }]
    }
  );

  return {
    addQuestion: ({ question, answer, isFeatured, references = null }) =>
      addQuestion({
        variables: { question: { question, answer, isFeatured, references } }
      }),
    loading,
    error,
    data
  };
};

export const useUpdateQuestion = () => {
  const webId = useGetCurrentWebsiteId();

  const [updateQuestion, { loading, error, data }] = useMutation(
    updateQuestionQuery,
    {
      variables: { webId },
      skip: !webId,
      refetchQueries: [{ query: questionsQuery, variables: { webId } }]
    }
  );

  return {
    updateQuestion: ({ id, question, answer, isFeatured, references = null }) =>
      updateQuestion({
        variables: {
          id,
          question: { question, answer, isFeatured, references }
        }
      }),
    loading,
    error,
    data
  };
};

export const useDeleteQuestion = () => {
  const webId = useGetCurrentWebsiteId();

  const [deleteQuestion, { loading, error, data }] = useMutation(
    deleteQuestionQuery,
    {
      variables: { webId },
      skip: !webId,
      refetchQueries: [{ query: questionsQuery, variables: { webId } }]
    }
  );

  return {
    deleteQuestion: ({ id }) => deleteQuestion({ variables: { id } }),
    loading,
    error,
    data
  };
};

export const useGenerateAnswer = () => {
  const webId = useGetCurrentWebsiteId();

  const [generateAnswer, { loading, error, data }] = useMutation(
    GenerateAnswerMutation,
    {
      variables: { webId },
      skip: !webId
    }
  );

  return {
    generateAnswer: question => generateAnswer({ variables: { question } }),
    loading,
    error,
    data
  };
};
