import React, { useState } from "react";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";

import Loader from "../Loader/Loader";
import UpdateQuestion from "./UpdateQuestion";
import Button from "../BasicElements/Button/Button";
import ArrowBack from "../Icons/ArrowDown";
import CheckCircle from "../Icons/CheckCircle";
import ArrowRightUpIcon from "../Icons/ArrowRightUp";

import { useDeleteQuestion, useGetQuestion } from "./questions.hook";

const QuestionItem = ({ QA, onEdit }) => {
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const { deleteQuestion } = useDeleteQuestion();

  const onDelete = async id => {
    setIsDeleting(true);
    await deleteQuestion({
      id: id
    });
    setIsDeleting(false);
    navigate("/knowledge/questions");
  };

  const references = QA.references ?? [];

  if (!QA) return <div>Question not found</div>;

  return (
    <>
      {/* Back to all items */}
      <header>
        <Button
          type="tertiary"
          size="sm"
          onClick={() => {
            navigate("/knowledge/questions");
          }}
        >
          <ArrowBack className="rotate-90 mr-3xs" />
          Back to all questions
        </Button>
      </header>
      {/* Main content */}
      <div className="py-3xl">
        {/* Meta information */}
        <div className="mb-sm">
          <span className="text-neutral-dark-l03 text-sm block mb-xs">
            Updated:{" "}
            {new moment(QA.updatedAt?.seconds * 1000).format("MMM DD, YYYY")}
          </span>
          {QA.user?.name && (
            <span className="text-neutral-dark-l03 text-sm block mb-xs">
              Author: {QA.user?.name}
            </span>
          )}
          {/* Highlight chip */}
          {QA.isFeatured && (
            <div className="rounded-full inline-flex items-center bg-accent-secondary-l03 text-xs px-esm py-e2xs text-accent-secondary-d03 mb-xs">
              <div className="flex items-center justify-center mr-4xs">
                <CheckCircle />
              </div>
              <span>Added to highlights</span>
            </div>
          )}
        </div>
        <div>
          {/* Title */}
          <h3 className="font-medium text-2xl max-w-2xl mb-e4xs">
            {QA.question}
          </h3>
          {/* Text */}
          <p className="text-neutral-dark-l02 max-w-2xl text-md">{QA.answer}</p>
        </div>
        <div className="pt-2xl">
          {references && references.length > 0 && (
            <h4 className="text-neutral-dark mb-3xs text-sm">
              Related links:
            </h4>
          )}
          <ul className="list-none max-w-xl">
          {references.map((reference, index) => (
            <li
              key={index}
              className="py-3xs text-sm flex justify-between items-center"
            >
              <a 
                className="flex items-center hover:underline" 
                href={reference.url} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <ArrowRightUpIcon 
                  className="mr-2xs"
                />
                {reference.title}
              </a>
            </li>
          ))}
          </ul>
        </div>
      </div>
      {/* Action buttons */}
      <footer className="md:flex">
        <Button
          onClick={() => {
            onEdit();
          }}
          className="mr-3xl"
        >
          Edit
        </Button>
        <Button type="tertiary" size="sm" onClick={() => onDelete(QA.id)}>
          {isDeleting ? "Deleting..." : "Delete"}
        </Button>
      </footer>
    </>
  );
};

const Question = () => {
  const { id } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const { question, refetch, loading } = useGetQuestion({ id });

  const questionRender = isEditing ? (
    <UpdateQuestion
      questionData={question}
      onUpdateQuestion={() => {
        setIsEditing(false);
        refetch();
      }}
    />
  ) : (
    <QuestionItem
      QA={question}
      onEdit={() => {
        setIsEditing(true);
      }}
    />
  );

  if (loading) return <Loader />;

  return (
    <section className="bg-neutral-light-l01 rounded p-4xl mb-2xl">
      {questionRender}
    </section>
  );
};

export default Question;
